import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ClipLoader } from "react-spinners";
import Skeleton from "react-loading-skeleton";
import {
  PlusIcon,
  TrashIcon,
  ArrowDownTrayIcon,
} from "@heroicons/react/24/solid";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faPlus,
  faTrash,
  faEye,
  faArrowLeft, // Added icon for back button
} from "@fortawesome/free-solid-svg-icons";

// Set the app element for accessibility
Modal.setAppElement("#root");

const CreateBookkeeping = () => {
  const { monthYearId } = useParams();
  const [records, setRecords] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteRecordId, setDeleteRecordId] = useState(null);
  const [formData, setFormData] = useState({
    outlet_name: "",
    item_name: "",
    unit_price: "",
    quantity_sold: "",
    bagi_hasil: "",
  });
  const [monthYearOptions, setMonthYearOptions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(6);
  const [totalRecords, setTotalRecords] = useState(0);
  const email = localStorage.getItem("userEmail");

  useEffect(() => {
    fetchRecords();
    fetchMonthYearOptions();
  }, [currentPage]);

  const fetchRecords = async () => {
    setLoadingData(true);
    try {
      const response = await axios.get(
        `https://convogenius-backend-production.up.railway.app/api/v1/records/monthyear/${monthYearId}/${email}?page=${currentPage}&limit=${recordsPerPage}`
      );

      // Extract data from the response
      const recordsData = response.data.data; // Assuming response.data.data contains the records

      setRecords(recordsData); // Directly set the array of records
      setTotalRecords(recordsData.length); // Set total count based on the length of the records array
    } catch (error) {
      console.error("Error fetching records:", error);
    } finally {
      setLoadingData(false);
    }
  };

  const fetchMonthYearOptions = async () => {
    try {
      const response = await axios.get(
        `https://convogenius-backend-production.up.railway.app/api/v1/monthyear/${email}`
      );
      setMonthYearOptions(response.data.monthyear);
    } catch (error) {
      console.error("Error fetching monthyear options:", error);
    }
  };

  const handleCreate = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setFormData({
      outlet_name: "",
      item_name: "",
      unit_price: "",
      quantity_sold: "",
      bagi_hasil: "",
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]:
        name === "unit_price" ||
        name === "quantity_sold" ||
        name === "bagi_hasil"
          ? parseFloat(value)
          : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await axios.post(
        `https://convogenius-backend-production.up.railway.app/api/v1/records/${email}`,
        { ...formData, month_year_id: parseInt(monthYearId) }
      );
      toast.success("Data created successfully!");
      fetchRecords();
      handleModalClose();
    } catch (error) {
      console.error("Error creating record:", error);
      toast.error("Failed to create data.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    if (deleteRecordId) {
      setIsLoading(true);
      try {
        await axios.delete(
          `https://convogenius-backend-production.up.railway.app/api/v1/records/${deleteRecordId}`
        );
        toast.success("Data deleted successfully!");
        fetchRecords();
        closeDeleteModal();
      } catch (error) {
        console.error("Error deleting record:", error);
        toast.error("Failed to delete data.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const openDeleteModal = (recordId) => {
    setDeleteRecordId(recordId);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteRecordId(null);
    setIsDeleteModalOpen(false);
  };

  const handleDownloadPDF = async () => {
    try {
      const response = await axios.get(
        `https://convogenius-backend-production.up.railway.app/api/v1/records/download/${email}/${monthYearId}`,
        {
          responseType: "blob", // Important for handling binary data
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "records.pdf"); // Adjust filename as needed
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading PDF:", error);
      toast.error("Failed to download PDF.");
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.max(Math.ceil(totalRecords / recordsPerPage), 1);

  return (
    <div className="p-6">
      <ToastContainer />
      {isLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50">
          <div className="flex flex-col items-center">
            <ClipLoader size={50} color={"#ffffff"} />
            <p className="text-white mt-4">Loading...</p>
          </div>
        </div>
      )}
      <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center mb-4">
        <div className="flex items-center mb-4">
          <a href="/make-bookkeeping">
            <button className="flex items-center text-blue-500 hover:text-blue-700 mb-4 mr-4">
              <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
              Back to Bookkeeping Records
            </button>
          </a>
        </div>
        <h1 className="text-2xl font-bold mb-4 lg:mb-0">
          Bookkeeping Details for {monthYearId}
        </h1>
        <div className="flex flex-col lg:flex-row space-y-2 lg:space-y-0 lg:space-x-2">
          <button
            onClick={handleCreate}
            className="bg-blue-600 text-white px-4 py-2 rounded-2xl flex items-center hover:bg-blue-700"
          >
            <PlusIcon className="h-5 w-5 mr-2" /> Create Data
          </button>
          <button
            onClick={handleDownloadPDF}
            className="bg-white border border-gray-800 text-gray-800 px-4 py-2 rounded-2xl flex items-center hover:bg-blue-700 hover:text-white hover:border-blue-700 transition-colors"
          >
            <ArrowDownTrayIcon className="h-5 w-5 mr-2" /> Download PDF
          </button>
        </div>
      </div>

      {/* Create Record Modal */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        className="fixed inset-0 flex justify-center items-center p-4"
        overlayClassName="fixed inset-0 bg-black bg-opacity-60"
      >
        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-3xl mx-auto my-8">
          <h2 className="text-2xl font-semibold mb-6">Create New Record</h2>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="flex flex-col">
                <label htmlFor="outlet_name" className="text-gray-700 mb-2">
                  Outlet Name
                </label>
                <input
                  type="text"
                  id="outlet_name"
                  name="outlet_name"
                  value={formData.outlet_name}
                  onChange={handleChange}
                  placeholder="Enter outlet name"
                  className="p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="item_name" className="text-gray-700 mb-2">
                  Item Name
                </label>
                <input
                  type="text"
                  id="item_name"
                  name="item_name"
                  value={formData.item_name}
                  onChange={handleChange}
                  placeholder="Enter item name"
                  className="p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="unit_price" className="text-gray-700 mb-2">
                  Unit Price
                </label>
                <input
                  type="number"
                  id="unit_price"
                  name="unit_price"
                  value={formData.unit_price || ""}
                  onChange={handleChange}
                  placeholder="Enter unit price"
                  className="p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="quantity_sold" className="text-gray-700 mb-2">
                  Quantity Sold
                </label>
                <input
                  type="number"
                  id="quantity_sold"
                  name="quantity_sold"
                  value={formData.quantity_sold || ""}
                  onChange={handleChange}
                  placeholder="Enter quantity sold"
                  className="p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="bagi_hasil" className="text-gray-700 mb-2">
                  Bagi Hasil
                </label>
                <input
                  type="number"
                  id="bagi_hasil"
                  name="bagi_hasil"
                  value={formData.bagi_hasil || ""}
                  onChange={handleChange}
                  placeholder="Enter bagi hasil"
                  className="p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
            </div>
            <div className="flex justify-end space-x-4">
              <button
                type="button"
                onClick={handleModalClose}
                className="bg-gray-500 text-white px-4 py-2 rounded-xl hover:bg-gray-600"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-blue-600 text-white px-4 py-2 rounded-xl hover:bg-blue-700 flex items-center"
                disabled={isLoading}
              >
                {isLoading && (
                  <ClipLoader size={20} color={"#ffffff"} className="mr-2" />
                )}
                Submit
              </button>
            </div>
          </form>
        </div>
      </Modal>

      {/* Delete Record Modal */}
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={closeDeleteModal}
        className="fixed inset-0 flex justify-center items-center p-4"
        overlayClassName="fixed inset-0 bg-black bg-opacity-60"
      >
        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md mx-auto my-8">
          <h2 className="text-xl font-semibold mb-4">Confirm Deletion</h2>
          <p className="mb-6">Are you sure you want to delete this record?</p>
          <div className="flex justify-end space-x-4">
            <button
              onClick={closeDeleteModal}
              className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
            >
              Cancel
            </button>
            <button
              onClick={handleDelete}
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 flex items-center"
              disabled={isLoading}
            >
              {isLoading && (
                <ClipLoader size={20} color={"#ffffff"} className="mr-2" />
              )}
              Delete
            </button>
          </div>
        </div>
      </Modal>

      {/* Table */}
      <div className="overflow-x-auto">
        {loadingData ? (
          <Skeleton count={10} height={50} className="mb-4" />
        ) : records && records.length === 0 ? (
          <p className="text-center text-gray-500 py-4">No Data Available</p>
        ) : (
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Outlet Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Item Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Unit Price
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Quantity Sold
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Total Price
                </th>

                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {records &&
                records.map((record) => (
                  <tr key={record.id}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {record.outlet_name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {record.item_name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {record.unit_price.toLocaleString("id-ID")}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {record.quantity_sold}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {record.total_price.toLocaleString("id-ID")}
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <TrashIcon
                        className="h-5 w-5 text-red-600 cursor-pointer hover:text-red-700"
                        onClick={() => openDeleteModal(record.id)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>

      {/* Pagination */}
      {/* {totalRecords > 0 && (
        <div className="flex justify-center mt-4">
          <nav className="flex space-x-2">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                className={`px-4 py-2 border rounded-md ${
                  currentPage === index + 1
                    ? "bg-blue-500 text-white"
                    : "bg-white text-blue-500"
                }`}
              >
                {index + 1}
              </button>
            ))}
          </nav>
        </div>
      )} */}
    </div>
  );
};

export default CreateBookkeeping;
