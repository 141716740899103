import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  EnvelopeIcon,
  LockClosedIcon,
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/solid";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [loginAttempts, setLoginAttempts] = useState(0);
  const [showResetPopup, setShowResetPopup] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    if (code) {
      handleGoogleCallback(code);
    }

    // Load email and password if Remember Me was checked
    const savedEmail = localStorage.getItem("savedEmail");
    const savedPassword = localStorage.getItem("savedPassword");
    if (savedEmail && savedPassword) {
      setEmail(savedEmail);
      setPassword(savedPassword);
      setRememberMe(true);
    }
  }, []);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    const requestData = {
      email,
      password,
    };

    fetch(
      "https://convogenius-backend-production.up.railway.app/api/v1/login",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      }
    )
      .then((response) => {
        setLoading(false);
        if (!response.ok) {
          setLoginAttempts((prev) => prev + 1);
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Success:", data);

        // Ensure that the response contains the required fields
        if (data && data.data && data.data[0]) {
          const { email, token } = data.data[0]; // Extract email and token

          // Store email and token in localStorage
          localStorage.setItem("userEmail", email);
          localStorage.setItem("authToken", token); // Store the JWT token

          console.log("JWT Token:", token); // Confirm token storage
          window.location.href = `/dashboard?email=${email}`;

          if (rememberMe) {
            localStorage.setItem("savedEmail", email);
            localStorage.setItem("savedPassword", password);
          } else {
            localStorage.removeItem("savedEmail");
            localStorage.removeItem("savedPassword");
          }
        } else {
          throw new Error("Email or token not found in the response");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("Your email and password are incorrect, please try again");

        if (loginAttempts >= 2) {
          setShowResetPopup(true);
        }
      });
  };

  // Function to handle Google login
  const handleGoogleLogin = () => {
    window.location.href =
      "https://convogenius-backend-production.up.railway.app/api/v1/auth/google";
  };

  // Function to handle Google callback
  const handleGoogleCallback = (code) => {
    fetch(
      `https://convogenius-backend-production.up.railway.app/api/v1/auth/google/callback?code=${code}`
    )
      .then((response) => {
        if (response.status === 200) {
          window.location.href = `/dashboard?email=${email}`;
        } else {
          throw new Error("Failed to login with Google");
        }
      })
      .catch((error) => {
        console.error("Google Login Error:", error);
        toast.error(
          "Failed to login with Google. Please try again or use another email."
        );
      });
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleRememberMeChange = () => {
    setRememberMe((prevRememberMe) => !prevRememberMe);
  };

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-white px-4 sm:px-6 lg:px-8">
      <Helmet>
        <title>Login Account | Convogenius</title>
        <meta
          name="description"
          content="Login to Convogenius to access your account and manage your branding and sales applications powered by AI. Secure and easy access to your personalized dashboard."
        />
        <meta
          name="keywords"
          content="login, Convogenius, account access, branding, sales applications, AI"
        />
        <meta name="author" content="Convogenius Team" />
        <meta property="og:title" content="Login Account | Convogenius" />
        <meta
          property="og:description"
          content="Login to Convogenius to access your account and manage your branding and sales applications powered by AI."
        />
        <meta
          property="og:image"
          content="https://www.convogenius.my.id/path-to-your-image.jpg"
        />
        <meta property="og:url" content="https://www.convogenius.my.id/login" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Convogenius" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Login Account | Convogenius" />
        <meta
          name="twitter:description"
          content="Login to Convogenius to access your account and manage your branding and sales applications powered by AI."
        />
        <meta
          name="twitter:image"
          content="https://www.convogenius.my.id/path-to-your-image.jpg"
        />
        <link rel="canonical" href="https://www.convogenius.my.id/login" />
        {/* Google Tag Manager */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-LR5JNC7FGT"
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-LR5JNC7FGT');
        `}
        </script>
      </Helmet>

      <div className="bg-white border border-gray-800 max-w-md w-full lg:w-1/2 p-8 rounded-xl shadow-2xl">
        <h2 className="text-3xl font-semibold text-center mb-4 text-gray-800">
          Sign in to Convogenius
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-800"
            >
              Email
            </label>
            <div className="mt-1 flex items-center border border-gray-600 rounded-md shadow-sm">
              <span className="inline-flex items-center px-3 bg-white text-white sm:text-sm">
                <EnvelopeIcon className="h-5 w-5 text-gray-400" />
              </span>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={handleEmailChange}
                className="p-3 w-full border-none rounded-r-md bg-white text-gray-800 focus:ring-indigo-500 focus:border-indigo-500  placeholder-gray-400 outline-none"
                placeholder="Your email"
                required
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-800"
            >
              Password
            </label>
            <div className="mt-1 flex items-center border border-gray-600 rounded-md shadow-sm">
              <span className="inline-flex items-center px-3 bg-white text-gray-800 sm:text-sm">
                <LockClosedIcon className="h-5 w-5 text-gray-400" />
              </span>
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                value={password}
                onChange={handlePasswordChange}
                className="p-3 w-full border-none rounded-r-md bg-white text-gray-800 focus:ring-indigo-500 focus:border-indigo-500 placeholder-gray-400 outline-none"
                placeholder="Your password"
                required
              />
              <span
                onClick={togglePasswordVisibility}
                className="inline-flex items-center px-3 bg-white text-white sm:text-sm cursor-pointer"
              >
                {showPassword ? (
                  <EyeSlashIcon className="h-5 w-5 text-gray-400" />
                ) : (
                  <EyeIcon className="h-5 w-5 text-gray-400" />
                )}
              </span>
            </div>
          </div>
          <div className="flex items-center">
            <div
              onClick={handleRememberMeChange}
              className={`relative inline-flex items-center h-6 rounded-full w-11 cursor-pointer ${
                rememberMe ? "bg-blue-600" : "bg-gray-400"
              }`}
            >
              <span
                className={`${
                  rememberMe ? "translate-x-6" : "translate-x-1"
                } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
              />
            </div>
            <label
              htmlFor="rememberMe"
              className="block text-sm text-gray-800 ml-3"
            >
              Remember me
            </label>
          </div>
          <div>
            <button
              type="submit"
              className={`w-full py-3 px-4 border border-transparent rounded-2xl shadow-sm text-sm font-medium text-white ${
                loading ? "bg-blue-700" : "bg-blue-600 hover:bg-blue-700"
              } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors`}
              disabled={loading}
            >
              {loading ? "Waiting to sign in your account" : "Sign In"}
            </button>
          </div>
        </form>
        <p className="mt-4 text-gray-800 text-center lg:text-left">
          Don't have an account yet?{" "}
          <a
            href="/register"
            className="text-black font-bold hover:text-blue-700"
          >
            Sign Up
          </a>
        </p>

        {/* Google Login Button */}
        <button
          onClick={handleGoogleLogin}
          className="mt-4 w-full py-3 px-4 border border-gray-300 rounded-2xl shadow-sm text-sm font-medium text-gray-800 bg-white hover:border-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2  transition-colors"
        >
          <i className="fab fa-google mr-2"></i> Continue with Google
        </button>
      </div>

      {showResetPopup && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-lg font-medium text-gray-900">
              Forgot your password?
            </h3>
            <p className="mt-2 text-sm text-gray-600">
              It seems like you have entered incorrect credentials multiple
              times. Would you like to reset your password?
            </p>
            <button
              onClick={() => (window.location.href = "/password-reset")}
              className="mt-4 w-full py-2 px-4 border border-transparent rounded-2xl shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Reset Password
            </button>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default LoginPage;
