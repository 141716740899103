import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faPlus,
  faTrash,
  faEye,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "../../index.css";
import { PulseLoader } from "react-spinners"; // Import spinner component
import { toast, ToastContainer } from "react-toastify";
// Mengimpor file CSS dari folder src

const RoomChatPage = () => {
  const [chatHistory, setChatHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(4);
  const [searchInput, setSearchInput] = useState("");
  const [filteredChatHistory, setFilteredChatHistory] = useState([]);
  const [animationDirection, setAnimationDirection] = useState("next"); // Animation direction state
  const navigate = useNavigate();

  useEffect(() => {
    const fetchChatHistory = async () => {
      const userEmail = localStorage.getItem("userEmail");
      if (!userEmail) {
        console.error("User email not found in local storage");
        return;
      }

      try {
        const response = await fetch(
          `https://convogenius-backend-production.up.railway.app/api/v1/chat-history/${userEmail}`
        );
        if (response.ok) {
          const result = await response.json();
          // Access the data field from the result
          const data = result.data;

          // Ensure the fetched data is an array
          if (Array.isArray(data)) {
            setChatHistory(data);
            setFilteredChatHistory(data);
          } else {
            console.error("Fetched data is not an array");
          }
        } else {
          console.error("Failed to fetch chat history");
        }
      } catch (error) {
        console.error("Error fetching chat history:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchChatHistory();
  }, []);

  useEffect(() => {
    const filteredData = chatHistory.filter((chat) =>
      chat.title.toLowerCase().includes(searchInput.toLowerCase())
    );
    setFilteredChatHistory(filteredData);
    setCurrentPage(1);
  }, [searchInput, chatHistory]);

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleDelete = async (chatId) => {
    const userEmail = localStorage.getItem("userEmail");
    try {
      const response = await fetch(
        `https://convogenius-backend-production.up.railway.app/api/v1/chat-history/${userEmail}/${chatId}`,
        { method: "DELETE" }
      );
      if (response.ok) {
        setChatHistory(chatHistory.filter((chat) => chat.id !== chatId));
        setFilteredChatHistory(
          filteredChatHistory.filter((chat) => chat.id !== chatId)
        );
      } else {
        console.error("Failed to delete chat history");
      }
    } catch (error) {
      console.error("Error deleting chat history:", error);
    }
  };

  const handleDeleteAll = async () => {
    const userEmail = localStorage.getItem("userEmail");
    try {
      const response = await fetch(
        `https://convogenius-backend-production.up.railway.app/api/v1/chat-history/${userEmail}`,
        { method: "DELETE" }
      );
      if (response.ok) {
        setChatHistory([]);
        setFilteredChatHistory([]);
      } else {
        console.error("Failed to delete all chat history");
      }
    } catch (error) {
      console.error("Error deleting all chat history:", error);
    }
  };

  const handleDeleteClick = async (id) => {
    setLoading((prev) => ({ ...prev, [id]: true })); // Set loading for specific ID
    try {
      await handleDelete(id); // Call the delete function and wait for it to complete
      toast.success("Delete successful!"); // Show success toast
    } catch (error) {
      console.error("Delete failed:", error);
      toast.error("Delete failed. Please try again."); // Show error toast
    } finally {
      setLoading((prev) => ({ ...prev, [id]: false })); // Reset loading for specific ID
    }
  };

  const handleView = (chatId) => {
    navigate(`/cht-ai/${chatId}`);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Array.isArray(filteredChatHistory)
    ? filteredChatHistory.slice(indexOfFirstItem, indexOfLastItem)
    : [];

  const totalPages = Math.ceil(
    Array.isArray(filteredChatHistory)
      ? filteredChatHistory.length / itemsPerPage
      : 0
  );

  const handleClick = (pageNumber) => {
    if (pageNumber > currentPage) {
      setAnimationDirection("next");
    } else {
      setAnimationDirection("prev");
    }
    setCurrentPage(pageNumber);
  };

  const pageNumbers = [];

  if (currentPage > 1) {
    pageNumbers.push(currentPage - 1);
  }

  pageNumbers.push(currentPage);

  if (currentPage < totalPages) {
    pageNumbers.push(currentPage + 1);
  }

  const handleBackToDashboard = () => {
    navigate("/dashboard");
  };

  return (
    <div className="container mx-auto p-4">
      <Helmet>
        <title>Room Chat Branding With AI | Convogenius</title>
      </Helmet>
      <div className="flex items-center mb-4">
        <button
          onClick={handleBackToDashboard}
          className="flex items-center bg-white border rounded-lg px-4 py-2 hover:bg-blue-600 text-blue-500 hover:text-white transition-colors mb-4 mr-4"
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
          Back to Dashboard
        </button>
      </div>
      <h1 className="text-3xl font-bold mb-4">Chat History</h1>
      <div className="flex flex-col sm:flex-row justify-between items-center mb-4">
        <div className="relative mb-4 sm:mb-0 w-full sm:w-auto">
          <input
            type="text"
            className="border rounded py-2 px-10 text-gray-700 w-full sm:w-auto"
            placeholder="Search by title..."
            value={searchInput}
            onChange={handleSearchChange}
          />
          <FontAwesomeIcon
            icon={faSearch}
            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500"
          />
        </div>
        <div className="flex items-center">
          <a href="/cht-ai">
            <button className="flex items-center bg-blue-500 text-white hover:bg-blue-700 hover:text-gray-200 transition-colors px-4 py-2 rounded-2xl shadow mr-2">
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              New Chat
            </button>
          </a>
          <button
            onClick={handleDeleteAll}
            className="flex items-center bg-red-500 text-white hover:bg-red-700 hover:text-gray-200 transition-colors px-4 py-2 rounded-2xl shadow"
          >
            <FontAwesomeIcon icon={faTrash} className="mr-2" />
            Delete All
          </button>
        </div>
      </div>
      <div className="overflow-x-auto">
        <TransitionGroup
          component="table"
          className="min-w-full bg-white shadow-md rounded-lg overflow-hidden"
        >
          <thead className="bg-gray-200">
            <tr>
              <th className="py-2 px-4 text-left text-sm font-medium text-gray-700">
                Title
              </th>
              <th className="py-2 px-4 text-left text-sm font-medium text-gray-700">
                Created At
              </th>
              <th className="py-2 px-4 text-left text-sm font-medium text-gray-700">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading
              ? Array.from({ length: itemsPerPage }).map((_, index) => (
                  <tr key={index}>
                    <td className="py-2 px-4 text-sm text-gray-700">
                      <Skeleton />
                    </td>
                    <td className="py-2 px-4 text-sm text-gray-500">
                      <Skeleton />
                    </td>
                    <td className="py-2 px-4 text-sm text-gray-500">
                      <Skeleton width={100} />
                    </td>
                  </tr>
                ))
              : currentItems.map((chat) => (
                  <CSSTransition
                    key={chat.id}
                    timeout={500}
                    classNames={
                      animationDirection === "next"
                        ? "slide-next"
                        : "slide-prev"
                    }
                  >
                    <tr>
                      <td className="py-2 px-4 text-sm text-gray-700">
                        {chat.title}
                      </td>
                      <td className="py-2 px-4 text-sm text-gray-500">
                        {new Date(chat.created_at).toLocaleString()}
                      </td>
                      <td className="py-2 px-4 text-sm text-gray-500">
                        <button
                          onClick={() => handleView(chat.id)}
                          className="text-blue-500 hover:text-blue-700 mr-2"
                        >
                          <FontAwesomeIcon icon={faEye} />
                        </button>
                        <button
                          onClick={() => handleDeleteClick(chat.id)}
                          className="text-red-500 hover:text-red-700"
                          disabled={loading[chat.id]} // Disable button when loading for specific ID
                        >
                          {loading[chat.id] ? (
                            <PulseLoader size={8} color="#ff0000" /> // Show spinner when loading for specific ID
                          ) : (
                            <FontAwesomeIcon icon={faTrash} />
                          )}
                        </button>
                      </td>
                    </tr>
                  </CSSTransition>
                ))}
            <ToastContainer />
          </tbody>
        </TransitionGroup>
      </div>
      <div className="flex justify-center mt-4">
        <ul className="flex items-center space-x-2">
          {pageNumbers.map((pageNumber) => (
            <li key={pageNumber}>
              <button
                onClick={() => handleClick(pageNumber)}
                className={`${
                  currentPage === pageNumber
                    ? "bg-blue-600 text-white"
                    : "bg-white text-blue-600 hover:bg-blue-600 hover:text-white"
                } transition-colors px-4 py-2 rounded-full shadow`}
              >
                {pageNumber}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default RoomChatPage;
