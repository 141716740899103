import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-modal"; // Import react-modal
import { ThreeDots } from "react-loader-spinner"; // Import loading spinner

Modal.setAppElement("#root"); // To avoid accessibility issues

const DeleteAccount = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false); // Modal state
  const [loading, setLoading] = useState(false); // Loading state
  const email = localStorage.getItem("userEmail");
  const token = localStorage.getItem("authToken");

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const openModal = () => {
    if (!isChecked) {
      toast.error("Anda harus mencentang kotak untuk melanjutkan!");
      return;
    }
    setModalIsOpen(true); // Open the modal
  };

  const closeModal = () => {
    setModalIsOpen(false); // Close the modal
  };

  const handleDeleteAccount = async () => {
    setLoading(true); // Set loading to true
    try {
      const response = await axios.delete(
        `https://convogenius-backend-production.up.railway.app/api/v1/${email}/delete-account`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Add the token here
          },
        }
      );

      if (response.status === 200) {
        toast.success(
          "Account successfully deleted. You will be directed to the registration page."
        );
        // Redirect to registration page after 2 seconds
        setTimeout(() => {
          window.location.href = "/register";
        }, 2000);
      }
    } catch (error) {
      toast.error("Failed to delete account. Please try again.");
    } finally {
      setLoading(false); // Set loading to false
    }
    closeModal(); // Close the modal after operation
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 px-4">
      <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold text-center mb-4">Delete Account</h2>
        <p className="text-gray-700 mb-4 text-center">
          Deleting your account will remove all your data from the system. You
          will not be able to access your account again after deletion. Make
          sure you have considered this decision carefully.
        </p>
        <div className="flex items-center mb-4">
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
            className="mr-2 h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
          />
          <label className="text-gray-700">
            I understand the consequences of deleting this account.
          </label>
        </div>
        <button
          onClick={openModal}
          disabled={!isChecked}
          className={`w-full py-2 text-white font-semibold rounded-lg 
            ${
              isChecked
                ? "bg-red-600 hover:bg-red-700"
                : "bg-gray-400 cursor-not-allowed"
            }
            transition duration-200`}
        >
          Delete Account
        </button>
        <ToastContainer />

        {/* Modal for confirming account deletion */}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          className="bg-white rounded-lg shadow-lg p-6 max-w-md mx-auto outline-none"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center px-4"
        >
          <div className="w-full">
            <h2 className="text-xl font-semibold text-center mb-4">
              Confirm Account Deletion
            </h2>
            <p className="text-gray-700 mb-6 text-center">
              Are you sure you want to delete your account? This action cannot
              be canceled.
            </p>
            <div className="flex justify-end space-x-2">
              <button
                onClick={closeModal}
                className="px-4 py-2 bg-gray-300 rounded-lg"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteAccount}
                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
              >
                {loading ? (
                  <ThreeDots
                    height="20"
                    width="20"
                    radius="9"
                    color="#ffffff"
                    ariaLabel="three-dots-loading"
                    visible={true}
                  />
                ) : (
                  "Delete Account"
                )}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default DeleteAccount;
