import React, { useState } from "react";

const DataPermissionsPage = () => {
  const [permissions, setPermissions] = useState({
    marketingEmails: false,
    locationTracking: false,
    dataSharing: false,
  });

  const handleToggle = (permission) => {
    setPermissions((prev) => ({
      ...prev,
      [permission]: !prev[permission],
    }));
  };

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <div className="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-lg">
        <h1 className="text-2xl font-semibold mb-6 text-gray-800">
          Data Permissions
        </h1>

        {/* Marketing Emails */}
        <div className="mb-6">
          <h2 className="text-lg font-semibold text-gray-700 mb-2">
            Marketing Emails
          </h2>
          <p className="text-sm text-gray-500 mb-4">
            Allow us to send you marketing emails with the latest offers and
            news.
          </p>
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-blue-500"
              checked={permissions.marketingEmails}
              onChange={() => handleToggle("marketingEmails")}
            />
            <span className="ml-2 text-sm text-gray-700">
              Receive Marketing Emails
            </span>
          </label>
        </div>

        {/* Location Tracking */}
        <div className="mb-6">
          <h2 className="text-lg font-semibold text-gray-700 mb-2">
            Location Tracking
          </h2>
          <p className="text-sm text-gray-500 mb-4">
            Allow us to track your location to provide personalized services and
            recommendations.
          </p>
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-blue-500"
              checked={permissions.locationTracking}
              onChange={() => handleToggle("locationTracking")}
            />
            <span className="ml-2 text-sm text-gray-700">
              Enable Location Tracking
            </span>
          </label>
        </div>

        {/* Data Sharing */}
        <div className="mb-6">
          <h2 className="text-lg font-semibold text-gray-700 mb-2">
            Data Sharing
          </h2>
          <p className="text-sm text-gray-500 mb-4">
            Allow us to share your data with third parties for better
            recommendations and services.
          </p>
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-blue-500"
              checked={permissions.dataSharing}
              onChange={() => handleToggle("dataSharing")}
            />
            <span className="ml-2 text-sm text-gray-700">
              Allow Data Sharing
            </span>
          </label>
        </div>

        {/* Save Changes Button */}
        <div className="mt-8 text-right">
          <button
            className="py-2 px-6 bg-blue-500 text-white rounded-lg shadow-lg hover:bg-blue-600 transition duration-300"
            onClick={() => {
              // Handle save logic here
              console.log("Permissions saved:", permissions);
            }}
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default DataPermissionsPage;
