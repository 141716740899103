import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import InfoModal from "../../components/InfoModal/InfoModal";
import "../../index.css"; // Pastikan jalur ini benar

const DashboardPage = () => {
  const [userEmail, setUserEmail] = useState("");
  const [userInfo, setUserInfo] = useState(null);
  const [youtubeTrending, setYoutubeTrending] = useState([]);
  const [loading, setLoading] = useState({
    youtube: true,
    tiktok: true,
    twitter: true,
  });
  const [error, setError] = useState(null);
  const [isFullDescription, setIsFullDescription] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [showModal, setShowModal] = useState(true); // Modal muncul saat pertama kali membuka halaman

  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    const email = localStorage.getItem("userEmail");
    const token = localStorage.getItem("authToken");
    setUserEmail(email);

    // Fetch user info from backend
    fetchUserInfo(email);

    // Fetch trending data
    fetchTrendingData();

    if (selectedVideo) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [selectedVideo]);

  const fetchUserInfo = (email) => {
    const token = localStorage.getItem("authToken");
    const endpoint = `https://convogenius-backend-production.up.railway.app/api/v1/dashboard/${email}`;

    fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setUserInfo(data.user);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const fetchTrendingData = () => {
    fetchYouTubeTrending();
    // You may want to fetch TikTok and Twitter trending data here
  };

  const fetchYouTubeTrending = () => {
    const endpoint = `https://convogenius-backend-production.up.railway.app/api/v1/youtube/trending`;

    fetch(endpoint)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch trending videos");
        }
        return response.json();
      })
      .then((result) => {
        if (result.status === 200 && Array.isArray(result.data)) {
          // Assuming you want to display the first 4 trending videos
          const trendingVideos = result.data.slice(0, 4);
          setYoutubeTrending(trendingVideos);
        } else {
          throw new Error("Unexpected response structure");
        }
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading((prev) => ({ ...prev, youtube: false }));
      });
  };

  fetchYouTubeTrending();

  const handleVideoClick = (video) => {
    setSelectedVideo(video);
  };

  const closePopup = () => {
    setSelectedVideo(null);
  };

  const formatTextWithLineBreaks = (text) => {
    return text ? text.replace(/\n/g, "<br />") : "";
  };

  const truncateText = (text, wordLimit) => {
    const words = text ? text.split(" ") : [];
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  const renderSkeletons = (count) => {
    return (
      <div className="flex overflow-x-auto space-x-4">
        {Array.from({ length: count }).map((_, index) => (
          <div
            key={index}
            className="bg-gray-200 p-4 rounded-lg shadow-lg flex flex-col items-center w-64 transition duration-300 animate-pulse"
          >
            <div className="w-full h-40 bg-gray-300 rounded-lg mb-4"></div>
            <div className="w-3/4 h-6 bg-gray-300 rounded mb-2"></div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="bg-white min-h-screen flex flex-col items-center justify-center p-4">
      <Helmet>
        <title>Dashboard | {userInfo?.Username || userEmail}</title>
      </Helmet>
      <div className="w-full max-w-6xl">
        <section className="w-full bg-blue-600 p-6 rounded-3xl mb-10 text-white flex flex-col lg:flex-row items-center lg:items-center">
          <div className="lg:w-1/2 mb-4 lg:mb-0 lg:mr-4 flex flex-col justify-center text-center lg:text-left">
            <h1 className="text-2xl lg:text-3xl font-bold mb-2 animate-slide-in-left">
              Welcome to Your Dashboard{" "}
              <span className="text-blue-600 bg-white px-3 py-1 rounded-xl mb-2 mt-2 block lg:inline-block">
                {userInfo?.Username || userEmail}
              </span>
            </h1>
            <p className="text-sm lg:text-base animate-slide-in-left">
              Here you can find the latest trends in social media and keep track
              of your sales data. Use the sections below to explore trending
              topics on YouTube, TikTok, and Twitter, and to manage your sales
              performance.
            </p>
          </div>
          <img
            src="images/dashboard/hello-user.png" // Replace with the actual image path
            alt="Dashboard Introduction"
            className="lg:w-1/2 w-full h-auto object-cover rounded-lg animate-slide-in-right mt-4 lg:mt-0"
          />
        </section>

        <h2 className="lg:text-3xl text-xl text-gray-800 font-semibold mb-2 text-center">
          Top 4 Trending Social Media
        </h2>
        <p className="text-lg text-gray-600 text-center mb-2 mt-2">
          Top Trending to help innovate your product
        </p>
        <div className="p-6 rounded-lg w-full transition duration-300">
          <h3 className="lg:text-2xl text-xl text-gray-800 font-semibold mb-4">
            Trending on YouTube
          </h3>
          {loading.youtube ? (
            renderSkeletons(4)
          ) : youtubeTrending.length > 0 ? (
            <div className="flex overflow-x-auto md:overflow-x-hidden space-x-4 pb-4">
              {youtubeTrending.map((item, index) => (
                <div
                  key={index}
                  className="text-gray-800 hover:text-white bg-white border border-blue-600 p-4 rounded-lg shadow-lg flex flex-col items-center min-w-[16rem] transition duration-300 hover:bg-blue-800"
                  onClick={() => handleVideoClick(item)} // Open popup on click
                >
                  <img
                    src={item.thumbnail_url}
                    alt={item.title}
                    className="w-full h-40 object-cover rounded-lg mb-4"
                  />
                  <h4 className="font-semibold mb-2 lg:text-sm text-sm text-center">
                    {item.title}
                  </h4>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-white">No data available</p>
          )}
        </div>

        {/* Modal Popup for Video */}
        {selectedVideo && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
            <div className="bg-white rounded-lg shadow-lg p-4 w-full max-w-2xl relative max-h-[90vh] overflow-y-auto">
              <button
                className="absolute top-1 right-1 text-gray-600 hover:text-gray-800"
                onClick={closePopup}
              >
                ✕
              </button>
              <iframe
                className="w-full h-64 rounded-lg"
                src={`https://www.youtube.com/embed/${selectedVideo.id}`}
                title={selectedVideo.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <h4 className="font-semibold text-xl mt-4">
                {selectedVideo.title}
              </h4>
              <p className="mt-2 text-gray-600">
                {isFullDescription ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: formatTextWithLineBreaks(
                        selectedVideo.description
                      ),
                    }}
                  />
                ) : (
                  <>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: formatTextWithLineBreaks(
                          truncateText(selectedVideo.description, 50)
                        ),
                      }}
                    />
                    <button
                      className="text-blue-500 ml-2"
                      onClick={() => setIsFullDescription(true)}
                    >
                      Read More
                    </button>
                  </>
                )}
              </p>
            </div>
          </div>
        )}

        <section className="bg-white p-6  mt-16 mb-10 text-gray-800 flex flex-col lg:flex-row items-center border-t border-gray-700 lg:items-center">
          <div className="w-full lg:w-1/2 mb-4 lg:mb-0 lg:mr-4 flex flex-col justify-center lg:border-b-0 lg:border-r lg:pr-4 border-b border-gray-700 pb-4 lg:pb-0">
            <h1 className="text-3xl font-bold mb-2">
              Check your Bookkeeping Comparison
            </h1>
            <p>
              Use this feature to keep an eye on your expenses and ensure you're
              staying within your budget. Compare your monthly expenses to see
              where you can save more.
            </p>
            <a href="/bookkeeping-comparison">
              <button className="bg-blue-600 text-white hover:bg-blue-700 mt-4 px-4 py-2 rounded-lg font-semibold flex items-center">
                <span className="mr-2">Check Now</span>
                <svg
                  className="w-4 h-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 5l7 7-7 7"
                  ></path>
                </svg>
              </button>
            </a>
          </div>
          <div className="w-full lg:w-1/2 mb-4 lg:mb-0 lg:ml-4 flex flex-col justify-center  lg:pl-4  border-gray-700 pt-4 lg:pt-0">
            <h1 className="text-3xl font-bold mb-2">
              Make a book of your finances and goods
            </h1>
            <p>
              Keep track of your investments and their performance over time.
              Analyze trends and make informed decisions to grow your portfolio.
            </p>
            <a href="/make-bookkeeping">
              <button className="bg-blue-600 text-white hover:bg-blue-700 mt-4 px-4 py-2 rounded-lg font-semibold flex items-center">
                <span className="mr-2">Make It Now</span>
                <svg
                  className="w-4 h-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 5l7 7-7 7"
                  ></path>
                </svg>
              </button>
            </a>
          </div>
        </section>

        <div className="mt-2 mb-20 lg:mb-8">
          <h3 className="lg:text-2xl text-xl text-gray-800 font-semibold mb-2 text-center">
            Explore Features
          </h3>
          <p className="text-lg text-gray-600 text-center mb-4 mt-2">
            Let's try the product branding feature using AI
          </p>
          <div className="flex justify-start lg:justify-center overflow-x-auto space-x-4">
            <div className="bg-white border border-blue-600 p-6 rounded-lg shadow-lg flex flex-col items-center w-64 min-w-max transition duration-300 group hover:bg-blue-800">
              <h4 className="text-gray-800 font-semibold mb-2 text-center transition duration-300 group-hover:text-white">
                Branding Deksripsi With AI
              </h4>
              <img
                src="images/fitur/chat.png" // Replace with actual image path
                alt="Chat with AI"
                className="w-full h-40 object-cover rounded-lg mb-4 transition duration-300 "
              />
              <a href="/room_cht">
                <button className="bg-blue-700 text-white px-4 py-2 rounded-lg font-semibold transition duration-300 group-hover:bg-gray-200 group-hover:text-blue-700">
                  Try Now
                </button>
              </a>
            </div>

            <div className="bg-white border border-blue-600 p-6 rounded-lg shadow-lg flex flex-col items-center w-64 min-w-max transition duration-300 group hover:bg-blue-800">
              <h4 className="text-gray-800 font-semibold mb-2 text-center transition duration-300 group-hover:text-white">
                Branding Image With AI
              </h4>
              <img
                src="images/fitur/draw.png" // Replace with actual image path
                alt="Draw with AI"
                className="w-full h-40 object-cover rounded-lg mb-4 transition duration-300"
              />
              <a href="/image-ai">
                <button className="bg-blue-700 text-white px-4 py-2 rounded-lg font-semibold transition duration-300 group-hover:bg-gray-200 group-hover:text-blue-700">
                  Try Now
                </button>
              </a>
            </div>

            <div className="bg-white border border-blue-600 p-6 rounded-lg shadow-lg flex flex-col items-center w-64 min-w-max transition duration-300 group hover:bg-blue-800">
              <h4 className="text-gray-800 font-semibold mb-2 text-center transition duration-300 group-hover:text-white">
                Branding Music With AI
              </h4>
              <img
                src="images/fitur/music.png" // Replace with actual image path
                alt="Music with AI"
                className="w-full h-40 object-cover rounded-lg mb-4 transition duration-300"
              />
              <a href="/music-ai">
                <button className="bg-blue-700 text-white px-4 py-2 rounded-lg font-semibold transition duration-300 group-hover:bg-gray-200 group-hover:text-blue-700">
                  Try Now
                </button>
              </a>
            </div>
          </div>
        </div>
        <InfoModal show={showModal} handleClose={handleCloseModal} />
        {/* {error && <p className="text-blue-500 mt-4">{error}</p>} */}
      </div>
    </div>
  );
};

export default DashboardPage;
