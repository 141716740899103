import React from "react";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem("userEmail"); // Asumsikan status login berdasarkan adanya email di localStorage

  const handleGoBack = () => {
    if (isLoggedIn) {
      navigate("/dashboard");
    } else {
      navigate("/");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-home text-white">
      <h1 className="text-4xl font-bold mb-4">404</h1>
      <p className="text-xl mb-4">Page Not Found</p>
      <button
        onClick={handleGoBack}
        className="text-white hover:text-gray-200 underline"
      >
        Go back to {isLoggedIn ? "Dashboard" : "Home"}
      </button>
    </div>
  );
};

export default NotFoundPage;
