import React, { useState, useEffect, useRef } from "react";
import { Line } from "react-chartjs-2";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrashAlt,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { format, parse } from "date-fns";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const SalesChartPage = () => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Total Sales (IDR)",
        data: [],
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
      {
        label: "Total Items Sold",
        data: [],
        borderColor: "rgb(255, 99, 132)",
        tension: 0.1,
      },
    ],
  });
  const [tableData, setTableData] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    outlet_name: "",
    sale_date: new Date(),
    total_sale_amount: "",
    total_items_sold: "",
    email: "",
  });
  const [editData, setEditData] = useState({
    id: "",
    outlet_name: "",
    sale_date: new Date(),
    total_sale_amount: "",
    total_items_sold: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const chartRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      const storedEmail = localStorage.getItem("userEmail");
      if (storedEmail) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          email: storedEmail,
        }));
        try {
          setIsLoading(true);
          const response = await fetch(
            `https://convogenius-backend-production.up.railway.app/api/v1/sale/${storedEmail}`
          );
          const data = await response.json();

          const labels = data.map((item) => item.sale_date);
          const salesData = data.map((item) => item.total_sale_amount);
          const itemsData = data.map((item) => item.total_items_sold);

          setChartData({
            labels,
            datasets: [
              { ...chartData.datasets[0], data: salesData },
              { ...chartData.datasets[1], data: itemsData },
            ],
          });

          const newTableData = data.map((item) => ({
            id: item._id,
            outlet_name: item.outlet_name,
            date: item.sale_date,
            totalSales: item.total_sale_amount,
            totalItems: item.total_items_sold,
          }));

          setTableData(newTableData);
          setIsLoading(false);
        } catch (error) {
          console.error("Failed to fetch data", error);
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, []);

  const handleAddModalToggle = () => setIsAddModalOpen(!isAddModalOpen);
  const handleEditModalToggle = () => setIsEditModalOpen(!isEditModalOpen);
  const handleInputChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const handleDateChange = (date) =>
    setFormData({ ...formData, sale_date: date });

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const {
      outlet_name,
      sale_date,
      total_sale_amount,
      total_items_sold,
      email,
    } = formData;
    const formattedDate = format(sale_date, "dd/MM/yyyy");
    const newTotalSales = parseFloat(total_sale_amount);
    const newTotalItems = parseFloat(total_items_sold);

    const userEmail = localStorage.getItem("userEmail");
    if (userEmail) {
      const response = await fetch(
        `https://convogenius-backend-production.up.railway.app/api/v1/sale/add/${userEmail}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            outlet_name,
            sale_date: formattedDate,
            total_sale_amount: newTotalSales,
            total_items_sold: newTotalItems,
          }),
        }
      );

      if (response.ok) {
        // Reset form data
        setFormData({
          outlet_name: "",
          sale_date: new Date(),
          total_sale_amount: "",
          total_items_sold: "",
          email: userEmail,
        });
        setIsAddModalOpen(false);
        // Refresh data
        window.location.reload();
      } else if (response.status === 409) {
        toast.error("Data already exists");
      } else {
        console.error("Failed to send data to the server");
      }
    }
  };

  const handleEditClick = (row) => {
    const parsedDate = parse(row.date, "dd/MM/yyyy", new Date());
    setEditData({
      id: row.id,
      outlet_name: row.outlet_name,
      sale_date: parsedDate,
      total_sale_amount: row.totalSales,
      total_items_sold: row.totalItems,
    });
    setIsEditModalOpen(true);
  };

  const handleEditFormSubmit = async (e) => {
    e.preventDefault();
    const { id, outlet_name, sale_date, total_sale_amount, total_items_sold } =
      editData;
    const formattedDate = format(sale_date, "dd/MM/yyyy");

    const userEmail = localStorage.getItem("userEmail");
    if (userEmail) {
      const response = await fetch(
        `https://convogenius-backend-production.up.railway.app/api/v1/sale/update/${userEmail}/${id}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            outlet_name,
            sale_date: formattedDate,
            total_sale_amount: parseFloat(total_sale_amount),
            total_items_sold: parseFloat(total_items_sold),
          }),
        }
      );

      if (response.ok) {
        // Close modal and refresh data
        setIsEditModalOpen(false);
        window.location.reload();
      } else {
        console.error("Failed to update data on the server");
      }
    }
  };

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);
  const paginatedData = tableData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="container mx-auto p-4">
      <ToastContainer />
      <button
        onClick={() => (window.location.href = "/dashboard")}
        className="mb-4 px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      >
        <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
        Back to Dashboard
      </button>
      <div className="flex flex-col items-center">
        <div className="w-full max-w-4xl">
          <Line ref={chartRef} data={chartData} />
        </div>
        <button
          onClick={handleAddModalToggle}
          className="mt-4 px-4 py-2 text-sm font-medium text-white bg-green-600 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
        >
          Add Sale Data
        </button>
      </div>
      <div className="mt-6 overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Outlet Name
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Date
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Total Sales (IDR)
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Total Items Sold
              </th>
              <th scope="col" className="relative px-6 py-3">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {isLoading ? (
              <tr>
                <td colSpan="5" className="text-center py-4">
                  <Skeleton count={5} />
                </td>
              </tr>
            ) : (
              paginatedData.map((row) => (
                <tr key={row.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {row.outlet_name}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {row.date}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {row.totalSales.toLocaleString("id-ID", {
                      style: "currency",
                      currency: "IDR",
                    })}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {row.totalItems}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <button
                      onClick={() => handleEditClick(row)}
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      <FontAwesomeIcon icon={faEdit} className="mr-2" />
                      Edit
                    </button>
                    <button
                      onClick={async () => {
                        if (
                          window.confirm(
                            "Are you sure you want to delete this record?"
                          )
                        ) {
                          try {
                            const userEmail =
                              localStorage.getItem("userEmail");
                            const response = await fetch(
                              `https://convogenius-backend-production.up.railway.app/api/v1/sale/delete/${userEmail}/${row.id}`,
                              {
                                method: "DELETE",
                              }
                            );
                            if (response.ok) {
                              window.location.reload();
                            } else {
                              console.error("Failed to delete record");
                            }
                          } catch (error) {
                            console.error("Failed to delete record", error);
                          }
                        }
                      }}
                      className="text-red-600 hover:text-red-900 ml-4"
                    >
                      <FontAwesomeIcon icon={faTrashAlt} className="mr-2" />
                      Delete
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <div className="flex justify-between items-center mt-4">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={`px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ${
              currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            Previous
          </button>
          <span className="text-sm font-medium text-gray-700">
            Page {currentPage}
          </span>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage * itemsPerPage >= tableData.length}
            className={`px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ${
              currentPage * itemsPerPage >= tableData.length
                ? "opacity-50 cursor-not-allowed"
                : ""
            }`}
          >
            Next
          </button>
        </div>
      </div>
      <Modal
        isOpen={isAddModalOpen}
        onRequestClose={handleAddModalToggle}
        contentLabel="Add Sale Data Modal"
        ariaHideApp={false}
      >
        <h2 className="text-2xl font-bold mb-4">Add Sale Data</h2>
        <form onSubmit={handleFormSubmit}>
          <div className="mb-4">
            <label
              htmlFor="outlet_name"
              className="block text-sm font-medium text-gray-700"
            >
              Outlet Name
            </label>
            <input
              type="text"
              id="outlet_name"
              name="outlet_name"
              value={formData.outlet_name}
              onChange={handleInputChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="sale_date"
              className="block text-sm font-medium text-gray-700"
            >
              Sale Date
            </label>
            <DatePicker
              id="sale_date"
              selected={formData.sale_date}
              onChange={handleDateChange}
              dateFormat="dd/MM/yyyy"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="total_sale_amount"
              className="block text-sm font-medium text-gray-700"
            >
              Total Sales Amount (IDR)
            </label>
            <input
              type="text"
              id="total_sale_amount"
              name="total_sale_amount"
              value={formData.total_sale_amount}
              onChange={handleInputChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="total_items_sold"
              className="block text-sm font-medium text-gray-700"
            >
              Total Items Sold
            </label>
            <input
              type="text"
              id="total_items_sold"
              name="total_items_sold"
              value={formData.total_items_sold}
              onChange={handleInputChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div className="mt-6">
            <button
              type="submit"
              className="w-full inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Add Data
            </button>
          </div>
        </form>
        <button
          onClick={handleAddModalToggle}
          className="mt-4 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        >
          Close
        </button>
      </Modal>

      <Modal
        isOpen={isEditModalOpen}
        onRequestClose={handleEditModalToggle}
        contentLabel="Edit Sale Data Modal"
        ariaHideApp={false}
      >
        <h2 className="text-2xl font-bold mb-4">Edit Sale Data</h2>
        <form onSubmit={handleEditFormSubmit}>
          <div className="mb-4">
            <label
              htmlFor="edit_outlet_name"
              className="block text-sm font-medium text-gray-700"
            >
              Outlet Name
            </label>
            <input
              type="text"
              id="edit_outlet_name"
              name="outlet_name"
              value={editData.outlet_name}
              onChange={(e) =>
                setEditData({ ...editData, outlet_name: e.target.value })
              }
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="edit_sale_date"
              className="block text-sm font-medium text-gray-700"
            >
              Sale Date
            </label>
            <DatePicker
              id="edit_sale_date"
              selected={editData.sale_date}
              onChange={(date) =>
                setEditData({ ...editData, sale_date: date })
              }
              dateFormat="dd/MM/yyyy"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="edit_total_sale_amount"
              className="block text-sm font-medium text-gray-700"
            >
              Total Sales Amount (IDR)
            </label>
            <input
              type="text"
              id="edit_total_sale_amount"
              name="total_sale_amount"
              value={editData.total_sale_amount}
              onChange={(e) =>
                setEditData({ ...editData, total_sale_amount: e.target.value })
              }
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="edit_total_items_sold"
              className="block text-sm font-medium text-gray-700"
            >
              Total Items Sold
            </label>
            <input
              type="text"
              id="edit_total_items_sold"
              name="total_items_sold"
              value={editData.total_items_sold}
              onChange={(e) =>
                setEditData({ ...editData, total_items_sold: e.target.value })
              }
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div className="mt-6">
            <button
              type="submit"
              className="w-full inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Save Changes
            </button>
          </div>
        </form>
        <button
          onClick={handleEditModalToggle}
          className="mt-4 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        >
          Close
        </button>
      </Modal>
    </div>
  );
};

export default SalesChartPage;
