import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Modal from "react-modal";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

Modal.setAppElement("#root");

const BookkeepingComparison = () => {
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [previousTotal, setPreviousTotal] = useState(0);
  const [modalImage, setModalImage] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalDescription, setModalDescription] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const email = localStorage.getItem("userEmail");
      if (!email) {
        console.error("Email not found in local storage");
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(
          `https://convogenius-backend-production.up.railway.app/api/v1/monthyear/${email}`
        );
        const result = await response.json();

        if (result.status !== 200 || !Array.isArray(result.data)) {
          throw new Error("Unexpected response structure or status");
        }

        const monthYears = result.data;

        if (monthYears.length === 0) {
          setModalTitle("No Data Available");
          setModalDescription(
            "You don't have any data, please add your data first."
          );
          setModalImage("/images/comparison/strategy.png"); // Use strategy image for no data
          setModalIsOpen(true);
          setLoading(false);
          return;
        }

        // Sort the monthYears array by month_year
        monthYears.sort((a, b) => {
          const dateA = new Date(a.month_year + "-01");
          const dateB = new Date(b.month_year + "-01");
          return dateA - dateB;
        });

        const labels = monthYears.map((my) => my.month_year);
        const totalPrices = monthYears.map((my) => {
          return my.records.reduce(
            (sum, record) => sum + (record.total_price || 0),
            0
          );
        });

        setChartData({
          labels: labels,
          datasets: [
            {
              label: "Total Price",
              data: totalPrices,
              backgroundColor: "rgba(0, 0, 255, 0.2)", // Blue with 20% transparency
              borderColor: "rgba(0, 0, 255, 1)", // Fully opaque blue
              borderWidth: 1,
            },
          ],
        });

        // Prepare data for the table
        const tableData = monthYears.map((my) => {
          const totalPrice = my.records.reduce(
            (sum, record) => sum + (record.total_price || 0),
            0
          );
          return {
            month_year: my.month_year,
            total_price: totalPrice,
          };
        });

        // Sort table data by total_price in descending order
        tableData.sort((a, b) => b.total_price - a.total_price);

        // Compare current and previous month's total prices
        const lastMonthTotal = totalPrices[totalPrices.length - 2] || 0;
        const currentMonthTotal = totalPrices[totalPrices.length - 1] || 0;

        if (currentMonthTotal > lastMonthTotal) {
          setModalTitle("Congratulations! More Data Added");
          setModalDescription(
            "Your data has increased compared to the previous month."
          );
          setModalImage("/images/comparison/happy.png"); // Set happy image for increase
          setModalIsOpen(true);
        } else if (currentMonthTotal < lastMonthTotal) {
          setModalTitle("Warning! Data Decreased");
          setModalDescription(
            "Your data has decreased compared to the previous month."
          );
          setModalImage("/images/comparison/angry.png"); // Set angry image for decrease
          setModalIsOpen(true);
        } else {
          setModalTitle("No Improvement Detected");
          setModalDescription(
            "There is no improvement in your data, let's create a strategy."
          );
          setModalImage("/images/comparison/strategy.png"); // Set strategy image for no improvement
          setModalIsOpen(true);
        }

        setTableData(tableData);
        setLoading(false);
        setPreviousTotal(currentMonthTotal);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [previousTotal]);

  useEffect(() => {
    if (modalIsOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [modalIsOpen]);

  const renderMedal = (rank) => {
    switch (rank) {
      case 1:
        return <span className="text-yellow-500">🥇</span>; // Gold medal
      case 2:
        return <span className="text-gray-500">🥈</span>; // Silver medal
      case 3:
        return <span className="text-yellow-700">🥉</span>; // Bronze medal
      default:
        return rank;
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <Helmet>
        <title>Bookkeeping Comparison | Convogenius</title>
      </Helmet>
      <h1 className="text-3xl font-bold text-center mb-8">
        Bookkeeping Comparison
      </h1>
      {loading ? (
        <div className="flex justify-center items-center h-80">
          <Skeleton count={1} height={300} width={600} />
        </div>
      ) : (
        <div className="max-w-4xl mx-auto">
          <Bar
            data={chartData}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: "top",
                },
                title: {
                  display: true,
                  text: "Total Price Comparison by Month-Year",
                },
              },
              scales: {
                x: {
                  beginAtZero: true,
                  grid: {
                    color: "rgba(255, 255, 255, 0.1)",
                  },
                },
                y: {
                  beginAtZero: true,
                  grid: {
                    color: "rgba(255, 255, 255, 0.1)",
                  },
                },
              },
            }}
          />
          <table className="min-w-full bg-white mt-8">
            <thead>
              <tr>
                <th className="px-4 py-2 border">Rank</th>
                <th className="px-4 py-2 border">Month-Year</th>
                <th className="px-4 py-2 border">Total Price</th>
              </tr>
            </thead>
            <tbody>
              {loading
                ? Array.from({ length: 5 }).map((_, index) => (
                    <tr key={index}>
                      <td className="px-4 py-2 border text-center">
                        <Skeleton width={30} height={20} />
                      </td>
                      <td className="px-4 py-2 border">
                        <Skeleton width={100} height={20} />
                      </td>
                      <td className="px-4 py-2 border">
                        <Skeleton width={70} height={20} />
                      </td>
                    </tr>
                  ))
                : tableData.map((item, index) => (
                    <tr key={index}>
                      <td className="px-4 py-2 border text-center">
                        {renderMedal(index + 1)}
                      </td>
                      <td className="px-4 py-2 border">{item.month_year}</td>
                      <td className="px-4 py-2 border">
                        {item.total_price
                          ? Number(item.total_price).toLocaleString("id-ID")
                          : "0"}
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      )}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Data Update Notification"
        className="fixed inset-0 flex justify-center items-center p-4"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-md mx-auto my-8 transform transition-all duration-300 ease-in-out">
          <div className="flex justify-center mb-4">
            <img src={modalImage} alt="Notification" className="w-40 h-40" />
          </div>
          <h2 className="text-2xl font-bold mb-4 text-center">{modalTitle}</h2>
          <p className="text-center">{modalDescription}</p>
          <div className="flex justify-center mt-4">
            <button
              onClick={closeModal}
              className="px-6 py-2 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition-colors"
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BookkeepingComparison;
