import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
  ArrowRightIcon,
  TrashIcon,
  ArrowDownTrayIcon,
} from "@heroicons/react/24/solid";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ArrowLeftIcon } from "@heroicons/react/24/outline";

const ImagePage = () => {
  const [userEmail, setUserEmail] = useState("");
  const [userInfo, setUserInfo] = useState(null);
  const [error, setError] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [responseStarted, setResponseStarted] = useState(false);
  const [showPrompts, setShowPrompts] = useState(true);

  useEffect(() => {
    const userEmail = localStorage.getItem("userEmail");
    const token = localStorage.getItem("authToken");
    setUserEmail(userEmail);

    fetchUserInfo(userEmail);
  }, []);

  const fetchUserInfo = (email) => {
    const token = localStorage.getItem("authToken");
    const endpoint = `https://convogenius-backend-production.up.railway.app/api/v1/dashboard/${email}`;

    fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const userInfo = data.user;
        setUserInfo(userInfo);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (newMessage.trim() === "") return;

    const message = {
      id: messages.length + 1,
      text: newMessage,
      type: "text",
      sender: "user",
      timestamp: new Date().toISOString(),
    };

    setMessages([...messages, message]);
    setNewMessage("");
    setLoading(true);
    setTimeElapsed(0);
    setResponseStarted(false);
    setShowPrompts(false);

    const timer = setInterval(() => {
      setTimeElapsed((prev) => prev + 1);
    }, 100);

    queryImage({ inputs: newMessage })
      .then((base64Image) => {
        const responseMessage = {
          id: messages.length + 2,
          text: base64Image,
          type: "image",
          sender: "bot",
          timestamp: new Date().toISOString(),
          prompt: newMessage,
        };
        setMessages((prevMessages) => [...prevMessages, responseMessage]);
        clearInterval(timer);
        setLoading(false);
        setResponseStarted(false);
      })
      .catch((error) => {
        setError(error.message);
        clearInterval(timer);
        setLoading(false);
      });
  };

  const queryImage = async (data) => {
    try {
      const response = await fetch(
        `https://convogenius-backend-production.up.railway.app/api/v1/generate-image-ai/${userEmail}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();

      // Assuming the image base64 code is stored in the first element of the data array
      const base64Image = result.data[0];

      return `data:image/jpeg;base64,${base64Image}`;
    } catch (error) {
      // Display the error message in a Toastify notification
      toast.error(`Error: ${error.message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      throw error; // Optionally re-throw the error if you need further handling
    }
  };

  const handlePromptClick = (prompt) => {
    const message = {
      id: messages.length + 1,
      text: prompt,
      type: "text",
      sender: "user",
      timestamp: new Date().toISOString(),
    };

    setMessages([...messages, message]);
    setLoading(true);
    setTimeElapsed(0);
    setResponseStarted(false);
    setShowPrompts(false);

    const timer = setInterval(() => {
      setTimeElapsed((prev) => prev + 1);
    }, 100);

    queryImage({ inputs: prompt })
      .then((base64Image) => {
        const responseMessage = {
          id: messages.length + 2,
          text: base64Image,
          type: "image",
          sender: "bot",
          timestamp: new Date().toISOString(),
          prompt: prompt,
        };
        setMessages((prevMessages) => [...prevMessages, responseMessage]);
        clearInterval(timer);
        setLoading(false);
        setResponseStarted(false);
      })
      .catch((error) => {
        setError(error.message);
        clearInterval(timer);
        setLoading(false);
      });
  };

  const handleClearMessage = (id) => {
    setMessages(messages.filter((message) => message.id !== id));
  };

  const handleDownload = (imageData, fileName) => {
    const link = document.createElement("a");
    link.href = imageData;
    link.download = `${fileName}.jpg`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="flex flex-col h-screen bg-white text-gray-800">
      <Helmet>
        <title>Branding Image With AI | {userEmail}</title>
      </Helmet>
      <header className="bg-white text-gray-800 py-2 px-4 flex justify-between items-center">
        <a href="/cht-ai">
          <button className="flex items-center px-4 py-2  text-gray-800 hover:text-gray-500 rounded transition duration-200 text-sm">
            <ArrowLeftIcon className="h-5 w-5 mr-2 text-sm" />
            Back To Chat AI
          </button>
        </a>
        <a href="/music-ai">
          <div className="flex items-center">
            <p className="text-sm mr-2 text-gray-800 hover:text-gray-500">
              Next to AI Music
            </p>
            <ArrowRightIcon className="h-5 w-5 text-gray-800 hover:text-gray-200" />
          </div>
        </a>
      </header>
      {showPrompts && (
        <div className="flex flex-col items-center mt-40 bg-white">
          <img src="images/logo.png" alt="Logo" className="mb-4 w-20 h-20" />
          <div className="grid grid-cols-2 gap-4">
            <PromptCard
              text="Make me a logo for my laundry business"
              onClick={() =>
                handlePromptClick("Make me a logo for my laundry business")
              }
            />
            <PromptCard
              text="Make me a logo for my chicken noodle business"
              onClick={() =>
                handlePromptClick(
                  "Make me a logo for my chicken noodle business"
                )
              }
            />
            <PromptCard
              text="Make me a logo for my electronics store business"
              onClick={() =>
                handlePromptClick(
                  "Make me a logo for my electronics store business"
                )
              }
            />
            <PromptCard
              text="Make me a logo for my car cleaning business"
              onClick={() =>
                handlePromptClick("Make me a logo for my car cleaning business")
              }
            />
          </div>
        </div>
      )}
      <main className="flex-1 p-4 overflow-y-auto">
        {messages.map((message) => (
          <Message
            key={message.id}
            message={message}
            onClear={() => handleClearMessage(message.id)}
            onDownload={() =>
              handleDownload(
                message.text,
                message.prompt || `image-${message.id}`
              )
            }
          />
        ))}
        {loading && !responseStarted && <SkeletonLoader />}
      </main>
      <form
        onSubmit={handleSendMessage}
        className="flex justify-center items-center py-2 px-4 lg:py-2"
      >
        <div className="flex items-center max-w-2xl w-full">
          <textarea
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type your message..."
            rows={1} // Starting with a single row; it will expand as needed
            className="flex-1 px-4 py-2 bg-white rounded-lg focus:outline-none focus:bg-gray-200 text-gray-800 border border-gray-300 resize-none overflow-y-auto shadow-md"
            style={{
              maxHeight: "200px", // Optional: max height limit for scrolling
              paddingRight: "2.5rem", // Adjust for aesthetics
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault(); // Prevent the default new line behavior
                handleSendMessage(e); // Call the function to send the message
              }
            }}
          />
          <button
            type="submit"
            className="ml-2 bg-blue-600 text-white p-3 rounded-full hover:bg-blue-700 focus:bg-blue-700 focus:outline-none"
          >
            {loading ? (
              <span className="text-sm">{(timeElapsed / 10).toFixed(1)}s</span>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-5 h-5"
              >
                <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z" />
              </svg>
            )}
          </button>
        </div>
      </form>

      {/* {error && <p className="text-blue-500 mt-2 ml-4">{error}</p>} */}
    </div>
  );
};
const SkeletonLoader = () => {
  return (
    <div className="flex justify-start mb-2">
      <div className="max-w-xs bg-gray-200 p-3 rounded-lg shadow-md">
        <div className="flex space-x-2">
          <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce"></div>
          <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce delay-200"></div>
          <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce delay-400"></div>
        </div>
      </div>
    </div>
  );
};

const Message = ({ message, onClear, onDownload }) => {
  const { text, sender, timestamp, type } = message;

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  return (
    <div
      className={`flex ${
        sender === "user" ? "justify-end" : "justify-start"
      } mb-2`}
    >
      {/* If sender is AI, add profile image */}
      {sender !== "user" && (
        <img
          src="images/logo.png" // AI profile image
          alt="AI Profile"
          className="w-8 h-8 border border-gray-500 rounded-full mr-2" // Style for AI profile image
        />
      )}

      <div className={`max-w-md ${sender === "user" ? "ml-auto" : "mr-auto"}`}>
        <div
          className={`rounded-lg ${
            sender === "user"
              ? "bg-blue-600 text-white"
              : "bg-gray-300 text-gray-900"
          } py-2 px-4 break-words`}
        >
          {type === "image" ? (
            <img src={text} alt="Generated" className="rounded-lg" />
          ) : (
            text
          )}
        </div>
        <div className="text-xs text-gray-500 mt-1 flex justify-between items-center">
          <span>{formatDate(timestamp)}</span>
          <div className="flex">
            {type === "image" && (
              <button
                onClick={onDownload}
                className="ml-2 text-gray-800 hover:text-gray-200 focus:outline-none"
              >
                <ArrowDownTrayIcon className="h-4 w-4" />
              </button>
            )}
            <button
              onClick={onClear}
              className="ml-2 text-red-700 hover:text-red-800 focus:outline-none"
            >
              <TrashIcon className="h-4 w-4" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const PromptCard = ({ text, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="bg-white border border-blue-600 text-gray-800 hover:text-white p-4  mr-2 ml-2  rounded-lg shadow-md cursor-pointer hover:bg-blue-700 transition duration-300"
    >
      {text}
    </div>
  );
};

export default ImagePage;
