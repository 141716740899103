import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";

const SecuritySettingsPage = () => {
  const [twoFactorAuth, setTwoFactorAuth] = useState(false);
  const [loginHistory, setLoginHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const email = localStorage.getItem("userEmail"); // Assuming email is stored in local storage

  // Fetch login history from API
  useEffect(() => {
    const fetchLoginHistory = async () => {
      try {
        const response = await axios.get(
          `https://convogenius-backend-production.up.railway.app/api/v1/user/login-history/${email}`
        );
        setLoginHistory(response.data.data);
      } catch (err) {
        setError("Failed to fetch login history");
      } finally {
        setLoading(false);
      }
    };
    fetchLoginHistory();
  }, [email]);

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <Helmet>
        <title>Security Settings | Convogenius AI</title>
      </Helmet>
      <div className="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-lg">
        <h1 className="text-2xl font-semibold mb-6 text-gray-800">
          Security Settings
        </h1>

        {/* Two-Factor Authentication Section */}
        <div className="mb-6">
          <h2 className="text-lg font-semibold text-gray-700 mb-2">
            Two-Factor Authentication (2FA)
          </h2>
          <p className="text-sm text-gray-500 mb-4">
            Add an extra layer of security to your account with two-factor
            authentication.
          </p>
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              className="form-checkbox h-5 w-5 text-blue-500"
              checked={twoFactorAuth}
              onChange={() => setTwoFactorAuth(!twoFactorAuth)}
            />
            <span className="ml-2 text-sm text-gray-700">
              Enable Two-Factor Authentication
            </span>
          </label>
        </div>

        {/* Recent Login Activity Section */}
        <div className="mb-6">
          <h2 className="text-lg font-semibold text-gray-700 mb-2">
            Recent Login Activity
          </h2>
          <p className="text-sm text-gray-500 mb-4">
            Review your recent login history to ensure your account is secure.
          </p>

          {loading ? (
            <p className="text-sm text-gray-500">Loading...</p>
          ) : error ? (
            <p className="text-sm text-red-500">{error}</p>
          ) : (
            <ul className="divide-y divide-gray-200">
              {loginHistory.map((entry, index) => (
                <li key={index} className="py-4 flex justify-between">
                  <div>
                    <p className="text-sm font-medium text-gray-700">
                      Device: {entry.device || "Unknown Device"}
                    </p>
                    <p className="text-sm text-gray-500">IP: {entry.ip}</p>
                  </div>
                  <p className="text-sm text-gray-500">
                    Last Login: {new Date(entry.timestamp).toLocaleString()}
                  </p>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default SecuritySettingsPage;
