import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import {
  EyeIcon,
  EyeSlashIcon,
  EnvelopeIcon,
  UserIcon,
} from "@heroicons/react/24/solid"; // Heroicons for icons

const ChangePasswordPage = () => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState(""); // New field for username
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleOldPasswordVisibility = () => {
    setShowOldPassword(!showOldPassword);
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      toast.error("New password and confirm password do not match");
      return;
    }

    const requestData = {
      email: email.trim(),
      username: username.trim(), // Include username in the request body
      old_password: oldPassword.trim(),
      new_password: newPassword.trim(),
    };

    setLoading(true);

    try {
      const response = await fetch(
        "https://convogenius-backend-production.up.railway.app/api/v1/reset-password",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        toast.success("Password changed successfully");
        setEmail("");
        setUsername(""); // Clear username after successful password change
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
      } else if (response.status === 400) {
        toast.error("User not found");
      } else if (response.status === 401) {
        toast.error("Old password does not match");
      } else {
        toast.error("An error occurred. Please try again.");
      }
    } catch (error) {
      toast.error("Network error. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 py-10 px-4 sm:px-6 lg:px-8">
      <Helmet>
        <title>Convogenius | Change Password</title>
      </Helmet>
      <div className="max-w-md mx-auto bg-white shadow-lg rounded-lg p-6">
        <h1 className="text-2xl font-bold mb-6 text-gray-900">
          Change Password
        </h1>

        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Email Field with Icon */}
          <div className="relative">
            <label htmlFor="email" className="block text-gray-700 font-medium">
              Email
            </label>
            <div className="relative">
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="mt-1 block w-full px-4 py-2 pl-10 bg-gray-100 border rounded-md focus:ring focus:ring-blue-200"
                placeholder="Enter your email"
              />
              <EnvelopeIcon className="h-5 w-5 text-gray-500 absolute left-3 top-1/2 transform -translate-y-1/2" />
            </div>
          </div>

          {/* Username Field */}
          <div className="relative">
            <label
              htmlFor="username"
              className="block text-gray-700 font-medium"
            >
              Username
            </label>
            <div className="relative">
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                className="mt-1 block w-full px-4 py-2 pl-10 bg-gray-100 border rounded-md focus:ring focus:ring-blue-200"
                placeholder="Enter your username"
              />
              <UserIcon className="h-5 w-5 text-gray-500 absolute left-3 top-1/2 transform -translate-y-1/2" />
            </div>
          </div>

          {/* Old Password Field with Toggle Visibility */}
          <div className="relative">
            <label
              htmlFor="old-password"
              className="block text-gray-700 font-medium"
            >
              Old Password
            </label>
            <div className="relative">
              <input
                type={showOldPassword ? "text" : "password"}
                id="old-password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                required
                className="mt-1 block w-full px-4 py-2 pr-10 bg-gray-100 border rounded-md focus:ring focus:ring-blue-200"
                placeholder="Enter your old password"
              />
              <button
                type="button"
                onClick={toggleOldPasswordVisibility}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 focus:outline-none"
              >
                {showOldPassword ? (
                  <EyeSlashIcon className="h-5 w-5" />
                ) : (
                  <EyeIcon className="h-5 w-5" />
                )}
              </button>
            </div>
          </div>

          {/* New Password Field with Toggle Visibility */}
          <div className="relative">
            <label
              htmlFor="new-password"
              className="block text-gray-700 font-medium"
            >
              New Password
            </label>
            <div className="relative">
              <input
                type={showNewPassword ? "text" : "password"}
                id="new-password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
                className="mt-1 block w-full px-4 py-2 pr-10 bg-gray-100 border rounded-md focus:ring focus:ring-blue-200"
                placeholder="Enter your new password"
              />
              <button
                type="button"
                onClick={toggleNewPasswordVisibility}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 focus:outline-none"
              >
                {showNewPassword ? (
                  <EyeSlashIcon className="h-5 w-5" />
                ) : (
                  <EyeIcon className="h-5 w-5" />
                )}
              </button>
            </div>
          </div>

          {/* Confirm Password Field with Toggle Visibility */}
          <div className="relative">
            <label
              htmlFor="confirm-password"
              className="block text-gray-700 font-medium"
            >
              Confirm Password
            </label>
            <div className="relative">
              <input
                type={showConfirmPassword ? "text" : "password"}
                id="confirm-password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                className="mt-1 block w-full px-4 py-2 pr-10 bg-gray-100 border rounded-md focus:ring focus:ring-blue-200"
                placeholder="Confirm your new password"
              />
              <button
                type="button"
                onClick={toggleConfirmPasswordVisibility}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 focus:outline-none"
              >
                {showConfirmPassword ? (
                  <EyeSlashIcon className="h-5 w-5" />
                ) : (
                  <EyeIcon className="h-5 w-5" />
                )}
              </button>
            </div>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            disabled={loading}
            className={`w-full bg-blue-600 text-white py-2 rounded-lg mt-4 ${
              loading ? "opacity-50 cursor-not-allowed" : "hover:bg-blue-700"
            }`}
          >
            {loading ? "Sending..." : "Change Password"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChangePasswordPage;
