import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faInfoCircle,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faLinkedinIn,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

const FooterSection = () => {
  return (
    <footer className="bg-gray-900 text-white py-12">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-12">
          <div>
            <h3 className="text-xl font-semibold mb-6">About Us</h3>
            <p className="text-base leading-relaxed">
              We are dedicated to providing the best AI solutions tailored to
              your needs.
            </p>
          </div>

          <div>
            <h3 className="text-xl font-semibold mb-6">Links</h3>
            <ul className="space-y-4">
              <li>
                <a
                  href="/"
                  className="flex items-center text-base hover:text-gray-400 transition duration-300"
                >
                  <FontAwesomeIcon icon={faHome} className="mr-2" />
                  Home
                </a>
              </li>
              <li>
                <a
                  href="/about"
                  className="flex items-center text-base hover:text-gray-400 transition duration-300"
                >
                  <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                  About
                </a>
              </li>
              <li>
                <a
                  href="/services"
                  className="flex items-center text-base hover:text-gray-400 transition duration-300"
                >
                  <FontAwesomeIcon icon={faPhone} className="mr-2" />
                  Services
                </a>
              </li>
              <li>
                <a
                  href="/contact"
                  className="flex items-center text-base hover:text-gray-400 transition duration-300"
                >
                  <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                  Contact
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="text-xl font-semibold mb-6">Contact</h3>
            <p className="text-base">52, Ketapang Street</p>
            <p className="text-base">Jakarta, Indonesia</p>
            <p className="text-base">yusriltc@gmail.com</p>
            <p className="text-base">+62 858-6067-1780</p>
          </div>

          <div>
            <h3 className="text-xl font-semibold mb-6">Follow Us</h3>
            <div className="flex space-x-6">
              <a
                href="/"
                className="text-gray-400 hover:text-white transition duration-300"
              >
                <FontAwesomeIcon icon={faFacebookF} className="h-6 w-6" />
              </a>
              <a
                href="/"
                className="text-gray-400 hover:text-white transition duration-300"
              >
                <FontAwesomeIcon icon={faLinkedinIn} className="h-6 w-6" />
              </a>
              <a
                href="/"
                className="text-gray-400 hover:text-white transition duration-300"
              >
                <FontAwesomeIcon icon={faInstagram} className="h-6 w-6" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-12 border-t border-gray-800 pt-6 text-center text-sm">
        <p>&copy; 2024 ConvogeniusAI. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default FooterSection;
