import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faTrashAlt,
  faCopy,
} from "@fortawesome/free-solid-svg-icons";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import Prism from "prismjs";
import { ToastContainer, toast } from "react-toastify"; // Import Toastify
import "react-toastify/dist/ReactToastify.css"; // Import Toastify CSS
// import "prismjs/themes/prism-funky.css";

const ChatPage = () => {
  const { chatId } = useParams();
  const [userEmail, setUserEmail] = useState("");
  const [userInfo, setUserInfo] = useState(null);
  const [error, setError] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [responseStarted, setResponseStarted] = useState(false);
  const [showPrompts, setShowPrompts] = useState(!chatId);

  useEffect(() => {
    const email = localStorage.getItem("userEmail");
    const token = localStorage.getItem("authToken");
    setUserEmail(email);

    fetchUserInfo(email);
    if (chatId) {
      fetchChatHistory(email, chatId);
    }
  }, [chatId]);

  const fetchUserInfo = (email) => {
    const token = localStorage.getItem("authToken");
    const endpoint = `https://convogenius-backend-production.up.railway.app/api/v1/dashboard/${email}`;

    fetch(endpoint, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((result) => {
        // Access the data field from the result
        const data = result.data;

        // Check if data is an array and has at least one user object
        if (Array.isArray(data) && data.length > 0) {
          const userInfo = data[0]; // Access the first user object
          setUserInfo(userInfo);
        } else {
          console.error("No user data found");
        }
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const fetchChatHistory = (email, chatId) => {
    const endpoint = `https://convogenius-backend-production.up.railway.app/api/v1/chat-detail/${email}/${chatId}`;

    fetch(endpoint)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch chat history");
        }
        return response.json();
      })
      .then((data) => {
        const chatHistory = data.data.messages || []; // Accessing 'messages' under 'data'
        const formattedMessages = chatHistory.flatMap((msg) => [
          {
            id: `${msg.id}-user`,
            text: msg.user_input,
            sender: "user",
            timestamp: msg.timestamp,
          },
          {
            id: `${msg.id}-bot`,
            text: msg.response,
            sender: "bot",
            timestamp: msg.timestamp,
          },
        ]);
        setMessages(formattedMessages);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (newMessage.trim() === "") return;

    const userMessage = {
      id: messages.length + 1,
      text: newMessage,
      type: "text",
      sender: "user",
      timestamp: new Date().toISOString(),
    };

    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setNewMessage("");
    setLoading(true);
    setTimeElapsed(0);
    setResponseStarted(false);
    setShowPrompts(false);

    const timer = setInterval(() => {
      setTimeElapsed((prev) => prev + 1);
    }, 100);

    const isGreeting = checkIfGreeting(newMessage);

    if (isGreeting) {
      const greetingResponse = {
        id: messages.length + 2,
        text: "",
        type: "text",
        sender: "bot",
        timestamp: new Date().toISOString(),
      };
      setMessages((prevMessages) => [...prevMessages, greetingResponse]);
      displayResponseWordByWord(
        greetingResponse.id,
        "Hello! How can I assist you?",
        timer
      );
    } else {
      queryText({ inputs: newMessage })
        .then((data) => {
          const generatedText = data.generated_text;
          const responseMessage = {
            id: messages.length + 2,
            text: "",
            type: "text",
            sender: "bot",
            timestamp: new Date().toISOString(),
          };
          setMessages((prevMessages) => [...prevMessages, responseMessage]);
          displayResponseWordByWord(responseMessage.id, generatedText, timer);
        })
        .catch((error) => {
          setError(error.message);
          clearInterval(timer);
          setLoading(false);
        });
    }

    saveChatHistory([...messages, userMessage]);
  };

  const displayResponseWordByWord = (messageId, responseText, timer) => {
    const words = responseText.split(" ");
    let index = 0;
    setResponseStarted(true);
    const wordInterval = setInterval(() => {
      setMessages((prevMessages) => {
        const updatedMessages = prevMessages.map((msg) => {
          if (msg.id === messageId && words[index] !== undefined) {
            return {
              ...msg,
              text: msg.text + (msg.text ? " " : "") + words[index],
            };
          }
          return msg;
        });
        return updatedMessages;
      });
      index++;
      if (index >= words.length) {
        clearInterval(wordInterval);
        clearInterval(timer);
        setLoading(false);
        setResponseStarted(false);
      }
    }, 300);
  };

  const queryText = async (data) => {
    let apiUrl = "";

    if (chatId) {
      // If chatId exists in the URL, use the API with chatId
      apiUrl = `https://convogenius-backend-production.up.railway.app/api/v1/chat/${chatId}/${userEmail}`;
    } else {
      // If no chatId, use the default API
      apiUrl = `https://convogenius-backend-production.up.railway.app/api/v1/chat-ai/${userEmail}`;
    }

    const response = await fetch(apiUrl, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(data),
    });

    const result = await response.json();
    return result;
  };

  const checkIfGreeting = (message) => {
    const greetings = ["hi", "hello", "hai", "halo"];
    const words = message.toLowerCase().split(" ");
    return words.some((word) => greetings.includes(word));
  };

  const handleClearMessage = (id) => {
    const updatedMessages = messages.filter((message) => message.id !== id);
    setMessages(updatedMessages);
    saveChatHistory(updatedMessages);
  };

  const handleCopyMessage = (text) => {
    navigator.clipboard.writeText(text);
  };

  const saveChatHistory = (messages) => {
    const userEmail = localStorage.getItem("userEmail");
    let storedHistories =
      JSON.parse(localStorage.getItem(`${userEmail}_chatHistories`)) || {};

    const lastKey = Object.keys(storedHistories).length;

    storedHistories[lastKey + 1] = {
      title: "Chat Session",
      messages,
      date: new Date().toLocaleString(),
    };

    localStorage.setItem(
      `${userEmail}_chatHistories`,
      JSON.stringify(storedHistories)
    );
  };

  const handlePromptClick = (prompt) => {
    const message = {
      id: messages.length + 1,
      text: prompt,
      type: "text",
      sender: "user",
      timestamp: new Date().toISOString(),
    };

    setMessages([...messages, message]);
    setLoading(true);
    setTimeElapsed(0);
    setResponseStarted(false);
    setShowPrompts(false);

    const timer = setInterval(() => {
      setTimeElapsed((prev) => prev + 1);
    }, 100);

    queryText({ inputs: prompt })
      .then((data) => {
        const generatedText = data.generated_text;
        const responseMessage = {
          id: messages.length + 2,
          text: "",
          type: "text",
          sender: "bot",
          timestamp: new Date().toISOString(),
        };
        setMessages((prevMessages) => [...prevMessages, responseMessage]);
        displayResponseWordByWord(responseMessage.id, generatedText, timer);
      })
      .catch((error) => {
        setError(error.message);
        clearInterval(timer);
        setLoading(false);
      });

    saveChatHistory([...messages, message]);
  };

  return (
    <div className="flex flex-col h-screen bg-white text-gray-800">
      <Helmet>
        <title>Branding Deksripsi With AI | {userEmail}</title>
      </Helmet>
      <header className="bg-white text-gray-800 py-2 px-4 flex justify-between items-center">
        <a href="/room_cht">
          <button className="flex items-center px-4 py-2  text-gray-800 hover:text-gray-500 rounded transition duration-200 text-sm">
            <ArrowLeftIcon className="h-5 w-5 mr-2 text-sm" />
            Back To Room Chat
          </button>
        </a>
        <a href="/image-ai">
          <div className="flex items-center">
            <p className="text-sm mr-2 text-gray-800 hover:text-gray-500">
              Next to AI image
            </p>
            <FontAwesomeIcon
              icon={faArrowRight}
              className="text-gray-800 hover:text-gray-200"
            />
          </div>
        </a>
      </header>
      {showPrompts && (
        <div className="flex flex-col items-center mt-40 bg-white">
          <img src="images/logo.png" alt="Logo" className="mb-4 w-20 h-20" />
          <div className="grid grid-cols-2 gap-4">
            <PromptCard
              text="Create a Food Branding Description"
              onClick={() =>
                handlePromptClick("Create a Food Branding Description")
              }
            />
            <PromptCard
              text="Create a Laundry Service Branding Description"
              onClick={() =>
                handlePromptClick(
                  "Create a Laundry Service Branding Description"
                )
              }
            />
            <PromptCard
              text="Create a Branding Description for my Electronics Store"
              onClick={() =>
                handlePromptClick(
                  "Create a Branding Description for my Electronics Store"
                )
              }
            />
            <PromptCard
              text="Create a Branding Description for my iced tea drink"
              onClick={() =>
                handlePromptClick(
                  "Create a Branding Description for my iced tea drink"
                )
              }
            />
          </div>
        </div>
      )}
      <main className="flex-1 p-4 overflow-y-auto">
        {messages.map((message) => (
          <Message
            key={message.id}
            message={message}
            onClear={() => handleClearMessage(message.id)}
            onCopy={() => handleCopyMessage(message.text)}
          />
        ))}
        {loading && !responseStarted && <SkeletonLoader />}
      </main>
      <form
        onSubmit={handleSendMessage}
        className="flex justify-center items-center py-2 px-4 lg:py-2" // Reduced vertical padding
      >
        <div className="flex items-center max-w-2xl w-full">
          <textarea
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type your message..."
            rows={1} // Starting with a single row; it will expand as needed
            className="flex-1 px-4 py-2 bg-white rounded-lg focus:outline-none focus:bg-gray-200 text-gray-800 border border-gray-300 resize-none overflow-y-auto shadow-md"
            style={{
              maxHeight: "200px", // Optional: max height limit for scrolling
              paddingRight: "2.5rem", // Adjust for aesthetics
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault(); // Prevent the default new line behavior
                handleSendMessage(e); // Call the function to send the message
              }
            }}
          />
          <button
            type="submit"
            className="ml-2 bg-blue-600 text-white p-3 rounded-full hover:bg-blue-700 focus:bg-blue-700 focus:outline-none"
          >
            {loading ? (
              <span className="text-sm">{(timeElapsed / 10).toFixed(1)}s</span>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-5 h-5"
              >
                <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z" />
              </svg>
            )}
          </button>
        </div>
      </form>

      {/* {error && <p className="text-blue-500 mt-2 ml-4">{error}</p>} */}
    </div>
  );
};

const SkeletonLoader = () => {
  return (
    <div className="flex justify-start mb-2">
      <div className="max-w-xs bg-gray-200 p-3 rounded-lg shadow-md">
        <div className="flex space-x-2">
          <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce"></div>
          <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce delay-200"></div>
          <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce delay-400"></div>
        </div>
      </div>
    </div>
  );
};

const Message = ({ message, onClear, onCopy }) => {
  const { text, sender, timestamp } = message;
  const [profilePicture, setProfilePicture] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [copied, setCopied] = useState(false);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  const processText = (text) => {
    if (!text) return ""; // Check if text is not undefined

    const parts = text.split(
      /(```[\s\S]*?```|`[^`]+`|\*\*[^*]+\*\*|^[0-9]+\.\s.*|^-\s.*)/gm
    );

    const formattedParts = [];

    parts.forEach((part, index) => {
      if (part.startsWith("```") && part.endsWith("```")) {
        const codeContent = part.slice(3, -3).trim();
        const highlightedCode = Prism.highlight(
          codeContent,
          Prism.languages.javascript, // Change language if needed
          "javascript"
        );

        formattedParts.push(
          <div
            key={index}
            className="relative bg-black p-4 rounded-md shadow-md mt-2"
          >
            <pre className="whitespace-pre-wrap text-sm">
              <code
                className="text-white"
                dangerouslySetInnerHTML={{ __html: highlightedCode }}
              />
            </pre>
            <button
              onClick={() => copyToClipboard(codeContent)}
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
              title="Copy code"
            >
              <FontAwesomeIcon icon={faCopy} />
            </button>
          </div>
        );
      } else if (part.startsWith("`") && part.endsWith("`")) {
        const inlineCode = part.slice(1, -1).trim();

        formattedParts.push(
          <span key={index} className="relative">
            <code className="text-white bg-black py-1 px-2 rounded-md font-mono">
              {inlineCode}{" "}
              <button
                onClick={() => copyToClipboard(inlineCode)}
                className="ml-2 text-gray-600 hover:text-gray-800"
                title="Copy inline code"
              >
                {" "}
                <FontAwesomeIcon icon={faCopy} />{" "}
              </button>
            </code>

            {/* <FontAwesomeIcon icon={faCopy} /> */}
          </span>
        );
      } else if (/\*\*[^*]+\*\*/.test(part)) {
        formattedParts.push(
          <h2
            key={index}
            className="lg:text-lg text-base font-semibold mt-3 mb-1"
          >
            {part.replace(/\*\*/g, "").trim()}
          </h2>
        );
      } else if (/^[0-9]+\.\s/.test(part)) {
        formattedParts.push(
          <li key={index} className="ml-5 list-decimal">
            {part.trim()}
          </li>
        );
      } else if (/^-\s/.test(part)) {
        formattedParts.push(
          <li key={index} className="ml-5 list-disc">
            {part.trim()}
          </li>
        );
      } else {
        formattedParts.push(
          <span key={index} className="block text-base leading-relaxed mt-1">
            {part.trim()}
          </span>
        );
      }
    });

    return formattedParts;
  };

  const copyToClipboard = (code) => {
    navigator.clipboard
      .writeText(code)
      .then(() => {
        toast.success("Code copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
        toast.error("Failed to copy code.");
      });
  };

  const handleCopy = () => {
    onCopy(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  useEffect(() => {
    Prism.highlightAll(); // Highlight all code blocks
  }, [text]);

  useEffect(() => {
    const email = localStorage.getItem("userEmail");
    const token = localStorage.getItem("authToken");

    if (email) {
      setIsLoggedIn(true);
      setUserEmail(email);

      const fetchUserProfile = async () => {
        try {
          const response = await fetch(
            `https://convogenius-backend-production.up.railway.app/api/v1/${email}/user`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (!response.ok) {
            throw new Error("Failed to fetch user profile");
          }

          const result = await response.json();

          if (result.status === 200 && result.data) {
            const base64Image = result.data.ProfileImage;
            const profileImageUrl = base64Image
              ? `data:image/jpeg;base64,${base64Image}`
              : "https://via.placeholder.com/150";

            setProfilePicture(profileImageUrl);
            localStorage.setItem("profilePicture", profileImageUrl);
          } else {
            console.error("User data not found or invalid status.");
            setProfilePicture("https://via.placeholder.com/150"); // Set default picture if data is missing
          }
        } catch (error) {
          console.error("Error fetching profile picture:", error);
          setProfilePicture("https://via.placeholder.com/150"); // Set default picture on error
        }
      };

      fetchUserProfile();
    }
  }, []);
  return (
    <div
      className={`flex ${
        sender === "user" ? "justify-end" : "justify-start"
      } mb-4`}
    >
      {/* If sender is AI, add AI profile image */}
      {sender !== "user" && (
        <img
          src="images/logo.png" // AI profile image
          alt="Convogenius Profile"
          className="w-8 h-8 border border-gray-500 rounded-full mr-2" // Style for AI profile image
        />
      )}
      <div
        className={`max-w-md w-full ${
          sender === "user" ? "ml-auto" : "mr-auto"
        }`}
      >
        <div
          className={`rounded-lg shadow ${
            sender === "user"
              ? "bg-blue-600 text-white"
              : "bg-gray-300 text-gray-900"
          } py-3 px-4 break-words`}
        >
          {processText(text)}
        </div>
        <div className="text-xs text-gray-500 mt-1 flex justify-end items-center space-x-2">
          <span>{formatDate(timestamp)}</span>
          <button
            onClick={handleCopy}
            className="text-gray-700 hover:text-gray-780 transition-colors focus:outline-none"
            aria-label="Copy message"
          >
            <FontAwesomeIcon icon={faCopy} />
          </button>
          <button
            onClick={onClear}
            className="text-red-700 hover:text-red-800 transition-colors focus:outline-none"
            aria-label="Delete message"
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </button>
        </div>
        {copied && <div className="text-xs text-green-500 mt-1">Copied!</div>}
      </div>
      {/* If sender is user, add user profile image */}
      {sender === "user" && (
        <img
          src={profilePicture} // User's profile image from state
          alt="User Profile"
          className="w-8 h-8 border border-gray-500 rounded-full ml-2" // Style for user profile image
        />
      )}
      <ToastContainer /> {/* Add ToastContainer to your component */}
    </div>
  );
};

const PromptCard = ({ text, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="bg-white border border-blue-600 text-gray-800 hover:text-white p-4 mr-2 ml-2 rounded-lg shadow-md cursor-pointer hover:bg-blue-700 transition duration-300"
    >
      {text}
    </div>
  );
};

export default ChatPage;
