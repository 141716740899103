import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Analytics } from "@vercel/analytics/react";
import { Link } from "react-router-dom";
import axios from "axios";

const HomePage = () => {
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    // Fetch blogs from the API
    axios
      .get(
        "https://convogenius-backend-production.up.railway.app/api/v1/blogs/get-blogs"
      )
      .then((response) => {
        // Access the 'data' field that contains the array of blogs
        const blogs = response.data.data; // Access the 'data' array from the JSON response
        setBlogs(blogs.slice(0, 3)); // Only take the latest 3 blogs
      })
      .catch((error) => console.error("Error fetching blogs:", error));
  }, []);

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("id-ID", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    });
  };

  const openInfoModal = () => {
    setIsInfoModalOpen(true);
  };

  const closeInfoModal = () => {
    setIsInfoModalOpen(false);
  };

  const openVideoModal = () => {
    setIsVideoModalOpen(true);
  };

  const closeVideoModal = () => {
    setIsVideoModalOpen(false);
  };

  return (
    <>
      <Helmet>
        <title>Convogenius | Branding and Sales Applications using AI</title>
        <meta
          name="description"
          content="Convogenius offers advanced branding and sales applications powered by AI. Enhance your business strategies and streamline your sales processes with cutting-edge technology."
        />
        <meta
          name="keywords"
          content="branding, sales applications, AI, artificial intelligence, business solutions, technology"
        />
        <meta name="author" content="Convogenius Team" />
        <meta
          property="og:title"
          content="Convogenius | Branding and Sales Applications using AI"
        />
        <meta
          property="og:description"
          content="Convogenius provides innovative branding and sales solutions powered by AI to help businesses thrive in the digital age."
        />
        <meta
          property="og:image"
          content="https://www.convogenius.my.id/path-to-your-image.jpg"
        />
        <meta property="og:url" content="https://www.convogenius.my.id" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Convogenius" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Convogenius | Branding and Sales Applications using AI"
        />
        <meta
          name="twitter:description"
          content="Convogenius offers advanced branding and sales applications powered by AI. Enhance your business strategies and streamline your sales processes with cutting-edge technology."
        />
        <meta
          name="twitter:image"
          content="https://www.convogenius.my.id/path-to-your-image.jpg"
        />
        <link rel="canonical" href="https://www.convogenius.my.id" />
        {/* Google Tag Manager */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-LR5JNC7FGT"
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-LR5JNC7FGT');
        `}
        </script>
      </Helmet>
      <Analytics />
      <div className="relative min-h-screen flex flex-col justify-center items-center bg-white rounded-b-3xl mb-2">
        <div className="relative text-center p-4 sm:p-8 mt-20">
          <p className="text-lg lg:text-xl mb-2 text-gray-800">
            INTRODUCING OUR PROJECT
          </p>
          <h1 className="text-4xl lg:text-6xl font-semibold mb-4 text-gray-800">
            <span className="text-blue-600">Welcome</span> To{" "}
            <span className="text-blue-600">Convogenius</span> <br /> Based
            <span className="text-blue-600"> Project</span>
          </h1>

          <p className="text-lg lg:text-xl mb-8">
            ConvogeniusAI is a Generative AI Text service that <br />
            was built to meet your various needs.
          </p>
          <div className="flex flex-row justify-center items-center space-x-4 mb-8">
            <button
              onClick={openInfoModal}
              className="bg-blue-600 text-white px-6 py-2 rounded-md transition duration-300 hover:bg-blue-700"
            >
              Info Project
            </button>
            <button
              onClick={openVideoModal}
              className="border border-blue-600 text-gray-800 px-6 py-2 rounded-md transition duration-300 hover:bg-blue-700 hover:text-white "
            >
              Watch Video
            </button>
          </div>

          {/* Card Section */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 pt-10 pb-10 ">
            <div className="border-emerald-300 border-l  text-gray-800 p-6">
              <h1 className="text-2xl text-left font-bold mb-2">
                Advanced Capabilities
              </h1>
              <p className="text-base text-left text-gray-800  transition-colors">
                Convogenius AI is designed with cutting-edge technology to
                generate high-quality, coherent text and realistic images,
                making it a powerful tool for a variety of creative and
                practical applications.
              </p>
            </div>
            <div className="border-l border-yellow-300  text-white p-6 ">
              <h1 className="text-2xl text-left font-bold mb-2 text-gray-800">
                Efficiency
              </h1>
              <p className="text-base text-left text-gray-800 transition-colors">
                By automating the generation of text and images, Convogenius AI
                significantly speeds up content creation processes, saving time
                and resources compared to manual efforts.
              </p>
            </div>
            <div className="border-l border-rose-500  p-6">
              <h1 className="text-2xl text-left font-bold mb-2 text-gray-800">
                Versatility
              </h1>
              <p className="text-base text-left text-gray-800 transition-colors">
                Convogenius AI can be applied across different domains such as
                marketing, entertainment, education, and more, offering
                versatile solutions tailored to diverse needs and industries.
              </p>
            </div>
          </div>

          {/* Partner Logos Section */}
          <div className="text-center mt-12 pb-20">
            <h2 className="text-3xl font-bold text-gray-800 mb-6">
              Our Partners
            </h2>
            <div className="grid grid-cols-2 sm:grid-cols-4 gap-8 justify-center items-center mt-4">
              <img
                src="images/patner/Google_logo.svg.webp"
                alt="Google Logo"
                className="lg:h-16 h-10"
              />
              <img
                src="images/patner/hf-logo.png"
                alt="Hugging Face Logo"
                className="lg:h-16 h-10"
              />
              <img
                src="images/patner/Go.svg.png"
                alt="Golang Logo"
                className="lg:h-16 h-10"
              />
              <img
                src="images/patner/reactjs.png"
                alt="React Js Logo"
                className="lg:h-20 h-14"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white text-center p-4   mx-3">
        <h2 className="text-3xl font-bold text-gray-800 mb-4">
          The Convogenius AI Platform
        </h2>
        <p className="text-lg text-gray-800 mb-4">
          Get relevant results at unprecedented speed with open and flexible
          enterprise solutions. Accelerate business outcomes with the
          combination of powerful search and generative AI.
        </p>
        <a href="/login">
          <button className="text-lg bg-blue-600 text-white py-2 px-4 rounded-full transition duration-300 hover:bg-blue-700">
            Get Started
          </button>
        </a>
      </div>

      <div className="toggle-content section flex items-center justify-center bg-white py-12 px-4 lg:px-40">
        <div className="container mx-auto flex flex-col lg:flex-row items-center">
          <div className="lg:w-1/2 lg:pl-16 flex flex-col order-1 sm:order-1">
            <div
              className="mb-2 flex"
              data-aos="fade-up"
              data-aos-duration="800"
            ></div>
            <h1
              className="text-3xl font-bold mb-4 text-gray-900"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              Generative AI
            </h1>
            <p
              className="text-gray-800 text-lg mb-6 mr-2"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              Explore AI search capabilities optimized for running RAG workloads
              with a fully integrated vector database.
            </p>
            <a href="/login">
              <button
                className="text-lg bg-blue-600 text-white py-2 px-4 rounded-full transition duration-300 hover:bg-blue-700"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                Learn More
              </button>
            </a>
          </div>
          <div className="lg:w-1/2 mb-4 lg:mb-0 lg:mt-0 mt-5 order-2 sm:order-2">
            <img
              src="images/content/illustration-generative-ai-homepage.png"
              alt="Convogenius AI"
              data-aos="fade-up"
              data-aos-duration="1200"
              className="w-full sm:w-full h-full lg:w-92 lg:h-full rounded-3xl mx-auto lg:mx-0"
            />
          </div>
        </div>
      </div>

      <div className="container mx-auto px-4 md:px-8 mt-8 mb-8">
        {/* Section Header */}
        <div className="text-center mb-8">
          <h1 className="text-4xl font-bold text-blue-600 mb-2">What's New</h1>
          <p className="text-lg text-gray-700 mb-4">
            Check out the latest announcements and updates from Convogenius.
          </p>
          {/* Button to View All Blogs */}
          <Link to="/blog">
            <button className="bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700 transition duration-300">
              View All Blogs
            </button>
          </Link>
        </div>

        {/* Blog Cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {blogs.map((blog) => (
            <Link key={blog.id} to={`/detail_blog/${blog.slug}`}>
              <div className="border rounded-lg shadow-lg shadow-blue-600 p-6 hover:shadow-xl hover:shadow-blue-700 transition duration-300">
                <h2 className="text-xl font-bold mb-2">
                  {truncateText(blog.title, 80)}
                </h2>
                <p className="text-gray-700 mb-4">
                  {truncateText(blog.description, 70)}
                </p>
                <p className="text-gray-500 text-sm">
                  {formatDate(blog.updated_at)}
                </p>
              </div>
            </Link>
          ))}
        </div>
      </div>

      {/* Info Modal */}
      {isInfoModalOpen && (
        <div className="fixed inset-0 flex justify-center items-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-8 rounded-lg max-w-lg">
            <h2 className="text-2xl font-semibold mb-4">Project Info</h2>
            <p className="text-lg mb-4">
              Project Convogenius is an artificial intelligence (AI) project
              which is being developed by a student named Syaiful Imanudin. This
              AI aims to be a capable system generate text generatively. Right
              now, the main focus Convogenius is on text generation
              capabilities, which means AI it can create new text based on the
              given input to her.
            </p>
            <button
              onClick={closeInfoModal}
              className="bg-blue-600 text-white px-4 py-2 rounded-md transition duration-300 hover:bg-blue-700"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Video Modal */}
      {isVideoModalOpen && (
        <div className="fixed inset-0 flex justify-center items-center bg-gray-800 bg-opacity-50 z-50">
          <div className=" rounded-lg overflow-hidden max-w-screen-lg">
            <div
              className="relative"
              style={{ paddingTop: "5%", width: "100%" }}
            >
              <div className="flex items-center justify-center">
                <video controls autoPlay className="w-full">
                  <source
                    src="/videos/introducing-devin-720p.mp4"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
            <button
              onClick={closeVideoModal}
              className="absolute top-4 right-4 bg-blue-500 text-white px-4 py-2 rounded-md transition duration-300 hover:bg-blue-600"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default HomePage;
