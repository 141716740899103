import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { EnvelopeIcon, UserIcon, PhoneIcon } from "@heroicons/react/24/solid"; // Heroicons for icons

const ManageAccountPage = () => {
  const [email, setEmail] = useState(""); // User email
  const [username, setUsername] = useState(""); // User username
  const [phoneNumber, setPhoneNumber] = useState(""); // User phone number
  const [loading, setLoading] = useState(false); // Loading state

  useEffect(() => {
    // Fetch email from local storage and set it to state
    const storedEmail = localStorage.getItem("userEmail"); // Adjust the key as needed
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestData = {
      username: username.trim(),
      phone_number: phoneNumber.trim(),
    };

    setLoading(true);

    try {
      const response = await fetch(
        `https://convogenius-backend-production.up.railway.app/api/v1/${email}/update-account`, // Constructed endpoint using email
        {
          method: "PUT", // Changed to PUT method
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        toast.success("Account details updated successfully!");
        setUsername(""); // Clear username field after success
        setPhoneNumber(""); // Clear phone number field
      } else if (response.status === 400) {
        toast.error("Invalid input. Please check the details.");
      } else if (response.status === 404) {
        toast.error("User not found.");
      } else {
        toast.error("An error occurred. Please try again.");
      }
    } catch (error) {
      toast.error("Network error. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 py-10 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md mx-auto bg-white shadow-lg rounded-lg p-6">
        <h1 className="text-2xl font-bold mb-6 text-gray-900">
          Manage Account
        </h1>

        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Email Field with Icon - Read-Only */}
          <div className="relative">
            <label htmlFor="email" className="block text-gray-700 font-medium">
              Email
            </label>
            <div className="relative">
              <input
                type="email"
                id="email"
                value={email}
                readOnly // Make email field read-only
                className="mt-1 block w-full px-4 py-2 pl-10 bg-gray-100 border rounded-md cursor-not-allowed" // Add cursor style
                placeholder="Enter your email"
              />
              <EnvelopeIcon className="h-5 w-5 text-gray-500 absolute left-3 top-1/2 transform -translate-y-1/2" />
            </div>
          </div>

          {/* Username Field with Icon */}
          <div className="relative">
            <label
              htmlFor="username"
              className="block text-gray-700 font-medium"
            >
              Username
            </label>
            <div className="relative">
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                className="mt-1 block w-full px-4 py-2 pl-10 bg-gray-100 border rounded-md focus:ring focus:ring-blue-200"
                placeholder="Enter your username"
              />
              <UserIcon className="h-5 w-5 text-gray-500 absolute left-3 top-1/2 transform -translate-y-1/2" />
            </div>
          </div>

          {/* Phone Number Field with Icon */}
          <div className="relative">
            <label htmlFor="phone" className="block text-gray-700 font-medium">
              Phone Number
            </label>
            <div className="relative">
              <input
                type="tel" // Changed to "tel" to allow only numeric input
                id="phone"
                value={phoneNumber}
                onChange={(e) => {
                  // Use regex to allow only digits
                  const value = e.target.value.replace(/\D/g, ""); // Remove non-digit characters

                  // Check if the length is less than or equal to 12 before updating state
                  if (value.length <= 12) {
                    setPhoneNumber(value); // Update the state with only numeric characters
                  }
                }}
                required
                className="mt-1 block w-full px-4 py-2 pl-10 bg-gray-100 border rounded-md focus:ring focus:ring-blue-200"
                placeholder="Enter your phone number"
              />
              <PhoneIcon className="h-5 w-5 text-gray-500 absolute left-3 top-1/2 transform -translate-y-1/2" />
            </div>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            disabled={loading}
            className={`w-full bg-blue-600 text-white py-2 rounded-lg mt-4 ${
              loading ? "opacity-50 cursor-not-allowed" : "hover:bg-blue-700"
            }`}
          >
            {loading ? "Updating..." : "Update Account"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ManageAccountPage;
