import React from "react";
import { Helmet } from "react-helmet";

const SettingsPage = () => {
  return (
    <div className="min-h-screen bg-gray-100 py-10 px-4 sm:px-6 lg:px-8 lg:mb-0 mb-20">
      <Helmet>
        <title>Settings | Convogenius AI</title>
      </Helmet>
      <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-6">
        <h1 className="text-2xl font-bold mb-6 text-gray-900">Settings</h1>

        {/* Your Account Section */}
        <div className="space-y-4">
          <h2 className="text-lg font-semibold text-gray-800">Your Account</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <a href="/manage-account">
              <button className="w-full bg-gray-200 hover:bg-gray-300 text-gray-800 py-3 px-4 rounded-lg shadow-md text-left">
                Manage Account
              </button>
            </a>
            <a href="/change-email">
              <button className="w-full bg-gray-200 hover:bg-gray-300 text-gray-800 py-3 px-4 rounded-lg shadow-md text-left">
                Change Email
              </button>
            </a>
            <a href="/change-password">
              <button className="w-full bg-gray-200 hover:bg-gray-300 text-gray-800 py-3 px-4 rounded-lg shadow-md text-left">
                Change Password
              </button>
            </a>
            <a href="/delete-account">
              <button className="w-full bg-gray-200 hover:bg-gray-300 text-gray-800 py-3 px-4 rounded-lg shadow-md text-left">
                Delete Account
              </button>
            </a>
          </div>
        </div>

        <hr className="my-8" />

        {/* Privacy and Security Section */}
        <div className="space-y-4">
          <h2 className="text-lg font-semibold text-gray-800">
            Privacy and Security
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <a href="/privacy-settings">
              <button className="w-full bg-gray-200 hover:bg-gray-300 text-gray-800 py-3 px-4 rounded-lg shadow-md text-left">
                Privacy Settings
              </button>
            </a>
            <a href="/security-settings">
              <button className="w-full bg-gray-200 hover:bg-gray-300 text-gray-800 py-3 px-4 rounded-lg shadow-md text-left">
                Security Settings
              </button>
            </a>
            <a href="/data-permissions">
              <button className="w-full bg-gray-200 hover:bg-gray-300 text-gray-800 py-3 px-4 rounded-lg shadow-md text-left">
                Data Permissions
              </button>
            </a>
            <a href="/manage-blocked-accounts">
              <button className="w-full bg-gray-200 hover:bg-gray-300 text-gray-800 py-3 px-4 rounded-lg shadow-md text-left">
                Manage Blocked Accounts
              </button>
            </a>
          </div>
        </div>

        <hr className="my-8" />

        {/* Help Center Section */}
        <div className="space-y-4">
          <h2 className="text-lg font-semibold text-gray-800">Help Center</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <a href="/help-articles">
              <button className="w-full bg-gray-200 hover:bg-gray-300 text-gray-800 py-3 px-4 rounded-lg shadow-md text-left">
                Help Articles
              </button>
            </a>
            <a href="/contact-support">
              <button className="w-full bg-gray-200 hover:bg-gray-300 text-gray-800 py-3 px-4 rounded-lg shadow-md text-left">
                Contact Support
              </button>
            </a>
            <a href="/report-problem">
              <button className="w-full bg-gray-200 hover:bg-gray-300 text-gray-800 py-3 px-4 rounded-lg shadow-md text-left">
                Report a Problem
              </button>
            </a>
            <a href="/terms-service">
              <button className="w-full bg-gray-200 hover:bg-gray-300 text-gray-800 py-3 px-4 rounded-lg shadow-md text-left">
                Terms of Service
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
