import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { useNavigate, useLocation } from "react-router-dom";
import {
  HomeIcon,
  UserIcon,
  ArrowRightEndOnRectangleIcon,
  DocumentPlusIcon,
  ChevronDownIcon,
  Cog6ToothIcon,
} from "@heroicons/react/24/outline";

const Navbar = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [profilePicture, setProfilePicture] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // State for loading spinner screen
  const navigate = useNavigate();
  const location = useLocation();

  // Function to handle logout
  const handleLogout = () => {
    setIsLogoutModalOpen(true);
  };

  // Function to confirm logout
  const confirmLogout = () => {
    setIsLoading(true); // Show loading spinner screen
    const email = localStorage.getItem("userEmail");
    const token = localStorage.getItem("authToken");

    fetch(
      "https://convogenius-backend-production.up.railway.app/api/v1/logout",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({ email, token }),
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then(() => {
        // Clear user session
        localStorage.removeItem("userEmail");
        localStorage.removeItem("authToken");
        localStorage.removeItem("profilePicture");
        // Update login state and user email
        setIsLoggedIn(false);
        setUserEmail("");
        setProfilePicture("");
        // Redirect to login page
        navigate("/login");
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setIsLoading(false); // Hide loading spinner screen
        setIsLogoutModalOpen(false); // Close modal
      });
  };

  useEffect(() => {
    const email = localStorage.getItem("userEmail");
    const token = localStorage.getItem("authToken");

    if (email) {
      setIsLoggedIn(true);
      setUserEmail(email);

      const fetchUserProfile = async () => {
        try {
          const response = await fetch(
            `https://convogenius-backend-production.up.railway.app/api/v1/${email}/user`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (!response.ok) {
            throw new Error("Failed to fetch user profile");
          }

          const result = await response.json();

          if (result.status === 200 && result.data) {
            const base64Image = result.data.ProfileImage;
            const profileImageUrl = base64Image
              ? `data:image/jpeg;base64,${base64Image}`
              : "https://via.placeholder.com/150";

            setProfilePicture(profileImageUrl);
            localStorage.setItem("profilePicture", profileImageUrl);
          } else {
            console.error("User data not found or invalid status.");
            setProfilePicture("https://via.placeholder.com/150"); // Set default picture if data is missing
          }
        } catch (error) {
          console.error("Error fetching profile picture:", error);
          setProfilePicture("https://via.placeholder.com/150"); // Set default picture on error
        }
      };

      fetchUserProfile();
    }
  }, []);

  // Function to toggle dropdown menu visibility
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  // useEffect to disable scrolling when the modal is open
  useEffect(() => {
    if (isLogoutModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isLogoutModalOpen]);

  return (
    <nav className="bg-white py-4 border-b border-gray-300">
      <div className="max-w-7xl mx-auto px-4 md:px-8 flex justify-between items-center">
        <div className="flex items-center">
          <img
            src="images/logo.png" // Ganti dengan path logo yang sesuai
            alt="Convogenius Logo"
            className="w-8 h-8 mr-3" // Menentukan ukuran logo dan memberikan jarak ke kanan
          />
          <a
            href="/"
            className="text-blue-600 rounded-xl hover:text-blue-700 transition-colors px-3 text-xl font-bold"
          >
            Convogenius
          </a>
        </div>

        <div className="hidden md:flex space-x-4 items-center">
          {!isLoggedIn ? (
            <>
              <a
                href="/login"
                className="text-black hover:text-white border border-gray-800 hover:bg-blue-700 hover:border-blue-700 px-3 py-2 rounded transition duration-300"
              >
                Login
              </a>
              <a
                href="/register"
                className="text-white bg-blue-600 hover:bg-blue-700 px-3 py-2 rounded transition duration-300"
              >
                Register
              </a>
            </>
          ) : (
            <div className="relative flex items-center">
              {/* <img
                src={profilePicture}
                alt="User Profile"
                className="w-10 h-10 rounded-full border-2 border-gray-200 mr-2"
              /> */}
              <button
                onClick={toggleDropdown}
                className="text-gray-800 hover:text-gray-900 hover:bg-gray-200 px-3 py-2 rounded-xl transition duration-300 flex items-center"
              >
                <img
                  src={profilePicture}
                  alt="Profile Picture"
                  className="w-10 h-10 rounded-full border-2 border-gray-200 mr-2"
                />
                {userEmail}
                <ChevronDownIcon
                  className={`ml-2 h-5 w-5 transition-transform transform ${
                    isDropdownOpen ? "rotate-180" : "rotate-0"
                  }`}
                />
              </button>
              {isDropdownOpen && (
                <div className="absolute top-12 right-0 mt-2 bg-white shadow-2xl rounded-xl py-1 w-60 z-30">
                  <a
                    href="/dashboard"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-200 rounded-xl flex items-center"
                  >
                    <HomeIcon className="h-5 w-5 mr-2" />
                    Dashboard
                  </a>
                  <a
                    href="/account"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-200 rounded-xl flex items-center"
                  >
                    <UserIcon className="h-5 w-5 mr-2" />
                    Account
                  </a>
                  <a
                    href="/settings"
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-200 rounded-xl flex items-center"
                  >
                    <Cog6ToothIcon className="h-5 w-5 mr-2" />
                    Settings
                  </a>
                  <button
                    onClick={handleLogout}
                    className="block px-4 py-2 text-gray-800 hover:bg-gray-200 w-full text-left rounded-xl flex items-center"
                  >
                    <ArrowRightEndOnRectangleIcon className="h-5 w-5 mr-2" />
                    Logout
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {/* Mobile Tab Bar */}
      <div
        className="fixed bottom-0 w-full bg-white z-50 rounded-xl border border-y-blue-800 md:hidden animate-border-move"
        style={{ borderImage: "linear-gradient(90deg, white, blue, white) 1" }}
      >
        <div className="flex justify-around py-2">
          {!isLoggedIn ? (
            <>
              <a
                href="/login"
                className={`flex flex-col items-center text-gray-700 ${
                  location.pathname === "/login"
                    ? "text-blue-800 font-bold"
                    : ""
                }`}
              >
                <UserIcon className="h-6 w-6 mb-1" />
                Login
              </a>
              <a
                href="/register"
                className={`flex flex-col items-center text-gray-700 ${
                  location.pathname === "/register"
                    ? "text-blue-800 font-bold"
                    : ""
                }`}
              >
                <DocumentPlusIcon className="h-6 w-6 mb-1" />
                Register
              </a>
            </>
          ) : (
            <>
              <button
                className={`flex flex-col items-center text-gray-700 ${
                  location.pathname === "/dashboard"
                    ? "text-blue-600 font-bold"
                    : ""
                }`}
                onClick={() => handleNavigation("/dashboard")}
              >
                <HomeIcon className="h-6 w-6 mb-1" />
                Home
              </button>
              <button
                className={`flex flex-col items-center text-gray-700 ${
                  location.pathname === "/account"
                    ? "text-blue-600 font-bold"
                    : ""
                }`}
                onClick={() => handleNavigation("/account")}
              >
                <UserIcon className="h-6 w-6 mb-1" />
                Account
              </button>
              <button
                className={`flex flex-col items-center text-gray-700 ${
                  location.pathname === "/settings"
                    ? "text-blue-600 font-bold"
                    : ""
                }`}
                onClick={() => handleNavigation("/settings")}
              >
                <Cog6ToothIcon className="h-6 w-6 mb-1" />
                Settings
              </button>
              <button
                className="flex flex-col items-center text-gray-700"
                onClick={handleLogout}
              >
                <ArrowRightEndOnRectangleIcon className="h-6 w-6 mb-1" />
                Logout
              </button>
            </>
          )}
        </div>
      </div>
      {/* Logout Confirmation Modal */}
      <>
        {/* Fullscreen Loading Spinner */}
        {isLoading && (
          <div className="fixed inset-0 bg-gray-900 bg-opacity-75 z-50 flex items-center justify-center">
            <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-white"></div>
          </div>
        )}

        {/* Modal for confirming logout */}
        <Modal
          isOpen={isLogoutModalOpen}
          onRequestClose={() => setIsLogoutModalOpen(false)}
          contentLabel="Logout Confirmation"
          className="bg-white p-6 rounded-lg shadow-lg max-w-md mx-auto mt-20"
          overlayClassName="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center"
        >
          <h2 className="text-lg font-semibold mb-4">Confirm Logout</h2>
          <p className="mb-6">Are you sure you want to log out?</p>

          <div className="flex justify-end space-x-4">
            <button
              className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400 transition duration-200"
              onClick={() => setIsLogoutModalOpen(false)}
            >
              Cancel
            </button>
            <button
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition duration-200"
              onClick={confirmLogout}
            >
              Logout
            </button>
          </div>
        </Modal>
      </>
    </nav>
  );
};

export default Navbar;
