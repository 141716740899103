import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FaTrash, FaCheck, FaTimes, FaCamera } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AccountPage = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [profilePicture, setProfilePicture] = useState("");
  const [bannerPicture, setBannerPicture] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [newUsername, setNewUsername] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newProfilePicture, setNewProfilePicture] = useState(null);
  const [newBannerPicture, setNewBannerPicture] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [createdAt, setCreatedAt] = useState("");

  useEffect(() => {
    const loggedInEmail = localStorage.getItem("userEmail");
    const token = localStorage.getItem("authToken");

    if (!loggedInEmail || !token) {
      toast.error("No logged in user found.");
      setIsLoading(false);
      return;
    }

    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://convogenius-backend-production.up.railway.app/api/v1/${loggedInEmail}/user`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const result = await response.json();

        if (result.status === 200 && result.data) {
          const user = result.data;

          setUsername(user.Username);
          setEmail(user.Email);
          setPaymentStatus(user.PaymentStatus);
          setCreatedAt(new Date(user.CreatedAt).getFullYear());

          // Convert string or base64 data to a URL
          setProfilePicture(
            user.ProfileImage
              ? `data:image/jpeg;base64,${user.ProfileImage}`
              : "https://via.placeholder.com/150"
          );
          setBannerPicture(
            user.ProfileBanner
              ? `data:image/jpeg;base64,${user.ProfileBanner}`
              : "https://via.placeholder.com/600x200"
          );
        } else {
          toast.error("User data not found.");
        }
      } catch (error) {
        toast.error("Error fetching user data.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleEditClick = () => {
    setIsEditing(true);
    setNewUsername(username);
    setNewEmail(email);
    setNewProfilePicture(profilePicture);
    setNewBannerPicture(bannerPicture);
  };

  const handleSaveClick = async () => {
    const token = localStorage.getItem("authToken");
    const loggedInEmail = localStorage.getItem("userEmail");

    try {
      if (newProfilePicture || newBannerPicture) {
        if (newProfilePicture) {
          const profileFormData = new FormData();
          profileFormData.append("profile_image", newProfilePicture);

          const profileResponse = await fetch(
            `https://convogenius-backend-production.up.railway.app/api/v1/${loggedInEmail}/upload-profile-image`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
              },
              body: profileFormData,
            }
          );

          if (!profileResponse.ok) {
            throw new Error("Failed to upload profile picture");
          }
        }

        if (newBannerPicture) {
          const bannerFormData = new FormData();
          bannerFormData.append("banner_image", newBannerPicture);

          const bannerResponse = await fetch(
            `https://convogenius-backend-production.up.railway.app/api/v1/${loggedInEmail}/upload-profile-banner-image`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
              },
              body: bannerFormData,
            }
          );

          if (!bannerResponse.ok) {
            throw new Error("Failed to upload banner picture");
          }
        }
      }

      // Update username
      const usernameResponse = await fetch(
        `https://convogenius-backend-production.up.railway.app/api/v1/${loggedInEmail}/edit-username`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ username: newUsername }),
        }
      );

      if (!usernameResponse.ok) {
        throw new Error("Failed to update username");
      }

      setUsername(newUsername);
      setIsEditing(false);
      toast.success("Account successfully updated!");
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    }
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewProfilePicture(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePicture(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleBannerPictureChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setNewBannerPicture(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setBannerPicture(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveProfilePicture = () => {
    setProfilePicture("https://via.placeholder.com/150");
    setNewProfilePicture(null); // Clear the newProfilePicture state
  };

  const handleRemoveBannerPicture = async () => {
    const loggedInEmail = localStorage.getItem("userEmail");
    const token = localStorage.getItem("authToken");

    if (!loggedInEmail || !token) {
      toast.error("No logged in user found.");
      return;
    }

    try {
      const response = await fetch(
        `https://convogenius-backend-production.up.railway.app/api/v1/${loggedInEmail}/delete-profile-banner-image`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        // Set default placeholder image after deletion
        setBannerPicture("https://via.placeholder.com/600x200");
        toast.success("Banner image removed successfully.");
      } else {
        toast.error("Failed to remove banner image. Please try again later.");
      }
    } catch (error) {
      toast.error("Error occurred while removing banner image.");
    }
  };

  return (
    <div className="bg-white min-h-screen flex flex-col items-center p-4">
      <Helmet>
        <title>Account | {email}</title>
      </Helmet>
      <ToastContainer />
      {isLoading ? (
        <SkeletonLoader />
      ) : (
        <>
          <div className="w-full max-w-3xl">
            <div className="relative">
              <img
                src={bannerPicture || "https://via.placeholder.com/600x200"}
                alt="Banner"
                className="w-full h-52 object-cover rounded-lg mb-6"
              />
              <div className="absolute bottom-0 left-0 ml-6 mb-4 transform translate-y-1/2">
                <img
                  src={profilePicture || "https://via.placeholder.com/150"}
                  alt="User Profile"
                  className="w-32 h-32 rounded-full border-4 border-gray-200"
                />
              </div>
              <div className="absolute top-4 right-4">
                <button
                  onClick={handleEditClick}
                  className="bg-blue-600 text-white p-2 rounded-full hover:bg-blue-800 focus:bg-blue-800 focus:outline-none transition-colors"
                >
                  Edit Profile
                </button>
              </div>
            </div>
            <div className="border border-gray-800  p-6 rounded-lg shadow-xl">
              <h2 className="text-3xl text-gray-800 font-semibold mb-6 text-center">
                Account Information
              </h2>
              <div className="text-gray-800 mb-4 grid grid-cols-2 gap-4">
                <div className="mb-2">
                  <span className="font-semibold">Username: </span>
                  {username}
                </div>
                <div className="mb-2">
                  <span className="font-semibold">Email: </span>
                  {email}
                </div>
                <div className="mb-2">
                  <span className="font-semibold">Payment Status: </span>
                  {paymentStatus === "paid" ? (
                    <FaCheck className="text-green-500" />
                  ) : (
                    <FaTimes className="text-red-500" />
                  )}
                </div>
                <div className="mb-2">
                  <span className="font-semibold">Created At: </span>
                  This account has been around since {createdAt}
                </div>
              </div>
            </div>
          </div>
          {isEditing && (
            <div className="pt-6 fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center overflow-auto">
              <div className="bg-white rounded-lg shadow-lg max-w-screen-md w-full mx-4 my-8 overflow-hidden">
                <div className="flex justify-between items-center p-4">
                  <button
                    onClick={handleCancelClick}
                    className="text-gray-800 text-xl focus:outline-none"
                  >
                    <FaTimes />
                  </button>
                  <h3 className="text-2xl text-gray-800 font-semibold">
                    Edit Profile
                  </h3>
                  <button
                    onClick={handleSaveClick}
                    className="bg-white border border-gray-800 text-black px-4 py-2 rounded-md hover:bg-gray-200 focus:bg-gray-200 focus:outline-none transition-colors"
                  >
                    Save
                  </button>
                </div>
                <div className="relative mb-6">
                  <img
                    src={
                      newBannerPicture || "https://via.placeholder.com/600x200"
                    }
                    alt="Banner"
                    className={`w-full h-52 object-cover rounded-lg mb-4 ${
                      newBannerPicture ? "opacity-100" : "opacity-50"
                    }`}
                  />
                  <div className="absolute top-0 right-0 m-4">
                    <button
                      onClick={handleRemoveBannerPicture}
                      className="bg-red-600 text-white p-2 rounded-full hover:bg-red-700 focus:bg-red-700 focus:outline-none"
                    >
                      <FaTrash />
                    </button>
                    <button
                      onClick={() =>
                        document.getElementById("bannerInput").click()
                      }
                      className="bg-blue-600 text-white p-2 rounded-full ml-2 hover:bg-blue-700 focus:bg-blue-700 focus:outline-none"
                    >
                      <FaCamera />
                    </button>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleBannerPictureChange}
                      id="bannerInput"
                      className="hidden"
                    />
                  </div>
                </div>
                <div className="relative mb-6 flex items-center">
                  <div className="relative w-32 h-32 ml-6">
                    <img
                      src={
                        newProfilePicture || "https://via.placeholder.com/150"
                      }
                      alt="User Profile"
                      className="w-32 h-32 rounded-full border-4 border-gray-200"
                    />
                    <button
                      onClick={() =>
                        document.getElementById("profileInput").click()
                      }
                      className="absolute bottom-0 right-0 bg-blue-600 text-white p-2 rounded-full hover:bg-blue-700 focus:bg-blue-700 focus:outline-none"
                    >
                      <FaCamera />
                    </button>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleProfilePictureChange}
                      id="profileInput"
                      className="hidden"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-4">
                  <div className="text-gray-800 mb-4">
                    <label className="block font-semibold mb-2">Username</label>
                    <input
                      type="text"
                      value={newUsername}
                      onChange={(e) => setNewUsername(e.target.value)}
                      className="w-full px-4 py-2 border text-gray-900 border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                    />
                  </div>
                  <div className="text-gray-800 mb-4">
                    <label className="block font-semibold mb-2">Email</label>
                    <input
                      type="email"
                      value={newEmail}
                      readOnly
                      className="w-full px-4 py-2 border text-gray-900 border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const SkeletonLoader = () => {
  return (
    <div className="w-full max-w-3xl">
      <div className="relative">
        <div className="w-full h-52 bg-gray-300 rounded-lg mb-6 animate-pulse"></div>
        <div className="absolute bottom-0 left-0 ml-6 mb-4 transform translate-y-1/2">
          <div className="w-32 h-32 bg-gray-300 rounded-full border-4 border-gray-200 animate-pulse"></div>
        </div>
        <div className="absolute top-4 right-4">
          <div className="w-24 h-10 bg-blue-300 rounded-full animate-pulse"></div>
        </div>
      </div>
      <div className="bg-gray-200 p-6 rounded-lg shadow-md">
        <h2 className="w-48 h-8 bg-gray-300 rounded-md mb-6 mx-auto animate-pulse"></h2>
        <div className="text-white mb-4 grid grid-cols-2 gap-4">
          <div className="mb-2">
            <div className="w-24 h-6 bg-gray-300 rounded-md mb-2 animate-pulse"></div>
            <div className="w-full h-6 bg-gray-300 rounded-md animate-pulse"></div>
          </div>
          <div className="mb-2">
            <div className="w-24 h-6 bg-gray-300 rounded-md mb-2 animate-pulse"></div>
            <div className="w-full h-6 bg-gray-300 rounded-md animate-pulse"></div>
          </div>
          <div className="mb-2">
            <div className="w-24 h-6 bg-gray-300 rounded-md mb-2 animate-pulse"></div>
            <div className="w-full h-6 bg-gray-300 rounded-md animate-pulse"></div>
          </div>
          <div className="mb-2">
            <div className="w-24 h-6 bg-gray-300 rounded-md mb-2 animate-pulse"></div>
            <div className="w-full h-6 bg-gray-300 rounded-md animate-pulse"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountPage;
